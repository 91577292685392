export const TOKEN_KEY = "";
export const NOME_KEY = "";
export const EMAIL_KEY = "";
export const CAT_KEY = "";
export const COD_KEY = "";
export const SUP_KEY = "";
export const GER_KEY = "";
export const MSG_KEY = "";

export const login = (token, nome, email, categoria, cod, super_, geren, msgm) => {
  localStorage.setItem("TOKEN_KEY", token);
  localStorage.setItem("NOME_KEY", nome);
  localStorage.setItem("EMAIL_KEY", email);
  localStorage.setItem("CAT_KEY", categoria);
  localStorage.setItem("COD_KEY", cod);
  localStorage.setItem("SUP_KEY", super_);
  localStorage.setItem("GER_KEY", geren);
  localStorage.setItem("MSG_KEY", msgm);
};

export const isAuthenticated = () => localStorage.getItem("TOKEN_KEY") !== null;
export const getToken = () => localStorage.getItem("TOKEN_KEY");
export const getNome = () => localStorage.getItem("NOME_KEY");
export const getEmail = () => localStorage.getItem("EMAIL_KEY");
export const getCat = () => localStorage.getItem("CAT_KEY");
export const getCod = () => localStorage.getItem("COD_KEY");
export const getSuper = () => localStorage.getItem("SUP_KEY");
export const getGerente = () => localStorage.getItem("GER_KEY");
export const getMsg = () => localStorage.getItem("MSG_KEY");


export const logout = () => {
  localStorage.removeItem("TOKEN_KEY");
};