import React, { Component } from 'react'
import { getToken } from '../../services/login'
import api from '../../services/api'
import './Monitor.css'
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { useState, useEffect } from 'react';
import Main from '../templates/Main'
import { RepeatOneSharp } from '@material-ui/icons';


//const[pedidoCabeca, setPedidoCabeca] = useState({requestCabeca()});
//const[pedidoItens, setPedidoItens] = useState({requestItens()});

const headerProps = {
  icon: 'check-square-o',
  title: " Monitor de Verbas"
}

const useRowStyles = makeStyles({
    root: {
      '& > *': {
        borderBottom: 'unset',
      },
    },
  });

async function requestVerbas() {
  try {
      const response = await api.get("/monitorverbas/get",
          {
              headers: {
                  'this.state-Type': 'application/json',
                  'token': getToken()
              }
          });
     return response.data.data
  } catch (err) {
      console.log(err)
      return 'ERRO'
  }
}

function formataData(aData){

  try{

    var data = `${aData.substring(8,10)}/${aData.substring(5,7)}/${aData.substring(0,4)}`
    return data

  }catch{
    return 'Erro na Data'
  }

}

async function postImage(SelectedFile, row){

  var bodyFormData = new FormData();
  bodyFormData.append('imagem',SelectedFile);
  bodyFormData.append("Id", row.Id)
  bodyFormData.append("grpVendas", row.GrpVendas.trim())
  bodyFormData.append('cnpj', row.CNPJ);
  bodyFormData.append('codCli',row.CodCliente);

  if(SelectedFile === null)
  return false;

  const response = await api.post(
    "/contratoverba/post",
    bodyFormData,
    {
      headers: {
          'Content-Type': 'multipart/form-data',
          'token': getToken()
      }
    }
  )

  if(response.status === 200)
  return true;
  return false;
  
}

function Row(props){ 

  const { row } = props;
  const classes = useRowStyles();
  const[open, setOpen] = React.useState(false);
  const [Valido, setValido] = useState(true);
  const [Msgem, setMsgem] = useState('');
  const [SelectedFile, setSelectedFile] = useState();

const handleSelect = event =>{

  event.persist();

  try{
  if(event.target.files[0].type === 'application/pdf'){
  setValido(true)
  setMsgem('');
  setSelectedFile(event.target.files[0])
  }else{
  setValido(false)
  setMsgem('Tipo de arquivo invalido, por favor, selecione outro!');
  }}catch(error){
    setValido(false);
    setMsgem('Tipo de arquivo invalido, por favor, selecione outro!');
  }
}

const handleClick = async event =>{

    
    var result = await postImage(SelectedFile, row);
    if(result)
    setMsgem("Documento enviado com sucesso!");
    else
      setMsgem("Erro ao enviar documento, por favor, tente novamente mais tarde.")
      setValido(false);

}
  
  
return (
      <React.Fragment>
        <TableRow className={classes.root}>
          <TableCell>
            <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
          <TableCell component="th" scope="row">{row.Id}</TableCell>
          <TableCell align="right">{row.CNPJ}</TableCell>
          <TableCell align="right">{row.Cliente}</TableCell>
          <TableCell align="right">{row.Status}</TableCell>
          <TableCell align="right">{row.ValorTotal}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box margin={1}>
                <Typography variant="h6" gutterBottom component="div">
                  Itens
                </Typography>
                <Table size="small" aria-label="purchases">
                  <TableHead>
                    <TableRow>
                      <TableCell>Parcela</TableCell>
                      <TableCell>Tipo</TableCell>
                      <TableCell align="right">Data Vencimento</TableCell>
                      <TableCell align="right">Data de Pagamento</TableCell>
                      <TableCell align="right">Pago?</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {row.itens.map((historyRow) => (                
                        <TableRow key={historyRow.Parcela}>
                        <TableCell component="th" scope="row">
                          {historyRow.Parcela}
                        </TableCell>
                        <TableCell>{historyRow.Z3_DESCRI}</TableCell>
                        <TableCell align="right">{formataData(historyRow.DtVenc)}</TableCell>
                        <TableCell align="right">{historyRow.DtPago===null?'-':formataData(historyRow.DtPago)}</TableCell>
                        <TableCell align="right">{historyRow.Pago}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
                <div hidden={row.Status !== 'AGUARDANDO DOCUMENTOS' && row.Status !== 'ANEXO RECUSADO'}>
                  <input className = 'sendFileInput' type = "file"  onChange={handleSelect}></input><br></br>
                  <h5>{  Msgem }</h5>
                  <br></br>
                  <button className = 'sendFileButtons' disabled={!Valido} onClick={handleClick}>Enviar</button>
                </div>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
      )
}

function SelecionaPedido () {

  const [mensagem, setMensagem] = useState('Carregando. . .');
  const [Verbas, setVerbas] = useState([])
  useEffect(() => {
    let isMounted = true;
    (async () => {
      try {
        const verbasResponse = await requestVerbas();
        if (isMounted) {
            setVerbas(verbasResponse);

        }
      }
      catch (e) {
        console.log('Error fetching pedidos: '+ e)
      }
    })();
    return () => {
      isMounted = false;
    }
  }, []);

  return (
  <div>
    <div>
      <h3>Verbas:</h3>
      <hr/>
      </div>
      <div id="main-div">
        {
          (Verbas.length==0)?<h1>{mensagem}</h1>:
          <div>
          <TableContainer>
            <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
               <TableCell />
               <TableCell>Id da Verba</TableCell>
               <TableCell align="right">CNPJ</TableCell>
               <TableCell align="right">Cliente</TableCell>
               <TableCell align="right">Status</TableCell>
               <TableCell align="right">Valor</TableCell>
              </TableRow>
           </TableHead>
           <TableBody>
             {Verbas.map((row) => (<Row key={row.IdVerba} row={row} />))}
           </TableBody>
          </Table>
          </TableContainer>
          </div>}
      </div>
      </div>
  )

}

export default function render() {
  return (
      <Main {...headerProps}>
          {SelecionaPedido()}
      </Main>
  );
}
