import React from 'react'
import Main from '../templates/Main'
import {getMsg} from '../../services/login'
import './Home.css'

export default props =>
    <Main icon="home" title=" Início" >
        <div className="display-4">Bem-Vindo!</div>
        <hr />
        <div className='bv-text'>
        <p className="mb-0">{getMsg()}</p>
        </div>
    </Main>