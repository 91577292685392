import React from 'react'
import './Resposta.css'

export default props =>
<div>
        <div className="display-4">Atenção!</div>
        <hr />
        <div id= 'msg-div'>
        <p id = "resp-msg" className="mb-0">O cliente enviado já consta na nossa base de dados e não pode ser incluído novamente.<br/><br/>
            Caso seja necessária alguma alteração, entre em contato com o comercial.
         </p>
        </div>
        <hr/>
        <a href='/app'><button id='ok-button'>Ok!</button></a>
</div>