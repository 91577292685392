import React, { Component } from 'react'
import Main from '../templates/Main'
import { getToken } from '../../services/login'
import api from '../../services/api'
import './Canhotos.css'
import Erro from './Erro'
import Sucesso from './Sucesso'

const headerProps = {
    icon: 'camera',
    title: " Envio de Canhotos"
}


export default class Canhotos extends Component {

    state = {
        notas: [],
        full_notas: [],
        escolhido: '',
        selectedFile: null,
        podeFile:true,
        render: '10',
        buttonState:0,
        data:'',
        base64String: null,
        flagFile:'0'

    }

    componentDidMount() {
            this.requestNotas();
            this.setState({data: this.getDataMin()})
    }

    getDataMin(){
        var data;
        data = new Date();
        console.log(`${data.getFullYear()}-${(data.getMonth() +1)<10?"0"+(data.getMonth() +1):(data.getMonth() +1)}-${data.getDate()<10?"0"+data.getDate():data.getDate()}`)
        return (`${data.getFullYear()}-${(data.getMonth() +1)<10?"0"+(data.getMonth() +1):(data.getMonth() +1)}-${data.getDate()<10?"0"+data.getDate():data.getDate()}`);
    }


    requestNotas = async e => {
        try {
            const response = await api.get("/canhotos/get",
                {
                    headers: {
                        'this.state-Type': 'application/json',
                        'token': getToken()
                    }
                });
            this.setState({ notas: response.data.data, full_notas: response.data.data });
        } catch (err) {
            console.log(err)
    
        }
    }

    getEscolhido() {
        return this.state.escolhido;
    }

    sendData = _escolhido => {
        
        console.log(_escolhido.C5_NUM);
        this.setState({escolhido:_escolhido, render:'2'})
        
    }

    renderRow() {
        return this.state.notas.map(nota => {
            return (
                <tr key={`${nota.C5_NOTA}`} onClick={e => { this.sendData(nota) }}>
                     <td>{nota.C5_NOTA}</td>
                    <td>{nota.C5_NUM}</td>
                    <td>{`${nota.C5_EMISSAO.substring(6)}/${nota.C5_EMISSAO.substring(4,6)}/${nota.C5_EMISSAO.substring(0,4)}`}</td>
                    <td>{`${nota.C5_CLIENTE}-${nota.C5_LOJACLI}`}</td>
                </tr>
            )
        })
    }

    renderTable() {
        return (
            <table id="notas-table">
                <thead>
                    <tr className="header">
                        <th>Número da Nota</th>
                        <th>Número do Pedido</th>
                        <th>Data de Emissão</th>
                        <th>Cod. Cliente - Loja</th>
                    </tr>
                </thead>
                <tbody>
                    {this.renderRow()}
                </tbody>
            </table>
        )
    }

    filtro = item => {
        let list = this.state.full_notas;
        let result = [];
        for (const aux of list) {
            if (aux.C5_NOTA.includes(item.toUpperCase()) || aux.C5_NUM.includes(item.toUpperCase()) 
            || aux.C5_CLIENTE.includes(item.toUpperCase()) || aux.C5_LOJACLI.includes(item.toUpperCase()) ) {
                result.push(aux);
            }
        }
        this.setState({ notas: result })
    }

    fileSelectedHandler = async event =>{
        console.log(event.target.files[0]);

        if(event.target.files[0].type === 'image/jpeg'){
            this.setState({
                selectedFile:event.target.files[0],
                base64String: await this.convertBase(event.target.files[0]),
                flagFile:'0'
            })
        }
        else
            this.setState({flagFile:'1',
                            buttonState:0})

        
    }

    toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
        this.setState({ buttonState: 1});
    });

    async convertBase (sFile) {
        
        const file = sFile;
        const result = await this.toBase64(file).catch(e => Error(e));
        if(result instanceof Error) {
           console.log('Error: ', result.message);
           return;
        }
        return result;
     }


    handleSubmit = async e => {

        e.preventDefault();

        const ret = {
            pedido: this.state.escolhido.C5_NUM,
            jpeg: this.state.base64String.replace(/^data:image\/[a-z]+;base64,/, ""),
            entrega: this.state.data.normalize('NFD').replace(/[\u0300-\u036f]/g, "").replace(/[^a-zA-Z0-9 \n]/g, "").toUpperCase()
        }

        console.log("Teste" + JSON.stringify(ret) + "|");
        try {
            const response = await api.put("/canhotos/put", ret, {
                headers: {
                    'this.state-Type': 'application/json',
                    'token': getToken()
                }
            });
            console.log(response.data);
            console.log(response.status);
            if(response.status === 200){
                this.setState({render:'1'})
            }
            else
            this.setState({render: '0'});
        }
        catch (err) {
            console.error("erro no handle: " + err.stack)
            this.setState({render: '0'});
            
        }
    }


    conditionalRender(){
        if(this.state.render === '0')
            return(<Erro></Erro>)

        else if(this.state.render === '1')
            return(<Sucesso></Sucesso>)


        else if(this.state.render==='10')
        return (
            <div className = 'dataForm'>
                <div>
                <h3>Selecione uma nota:</h3>
                <hr/>
                <input type="text" id="filtro" onChange={e => this.filtro(e.target.value)} placeholder="Procurar nota..."></input>
                </div>
                <div id="main-div">
                    {this.state.notas===''?<h1 >Carregando...</h1>:this.renderTable()}
                </div>
            </div>
        )

        else{
            return (
                <div className = 'dataForm'>
                    <div>
                        <h4>Selecione a data da entrega:</h4>
                    <input type="date" required onChange={e => this.setState({ data: e.target.value })}
                        value = {this.state.data}
                    ></input>
                    </div>
                    <h4>Escolha um arquivo para enviar:</h4>
                    <div><input type = "file"   onChange={this.fileSelectedHandler}></input><br></br>
                    <p>{this.state.flagFile === '1'?"Tipo de arquivo invalido, por favor, selecione outro!":""}</p>
                    <br></br>
                    </div>
                    <div>
                    <button onClick={this.handleSubmit} disabled={this.state.buttonState===0} id = 'avanc-button' >Confirmar</button>
                    </div>
                    
                </div>
            )

            //<!-- accept="image/jpeg" -->
        }
    }

  

    render() {
        return(
            <Main {...headerProps}>
            {this.conditionalRender()}
            </Main>
        )
    }
}