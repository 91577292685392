import React, { Component } from 'react'
import { getToken } from '../../../services/login'
import api from '../../../services/api'
//import './Escolha.css'
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { useState, useEffect } from 'react';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';  

//const[pedidoCabeca, setPedidoCabeca] = useState({requestCabeca()});
//const[pedidoItens, setPedidoItens] = useState({requestItens()});


const useRowStyles = makeStyles({
    root: {
      '& > *': {
        borderBottom: 'unset',
      },
    },
  });

async function requestCabeca() {
  try {
      const response = await api.get("/pedidos/get_cabecalho",
          {
              headers: {
                  'this.state-Type': 'application/json',
                  'token': getToken()
              }
          });
     return response.data.data
  } catch (err) {
      console.log(err)
      return 'ERRO'
  }
}

async function requestItens() {
  try {
      const response = await api.get("/pedidos/get_itens",
          {
              headers: {
                  'this.state-Type': 'application/json',
                  'token': getToken()
              }
          });
      return response.data.data;
  } catch (err) {
      console.log(err)
      return 'ERRO';
  }
}

async function requestProdutos() {
  try {
    //http://srqvendas.sitiorecantodoqueijo.com.br:10805/produtos/get
      const response = await api.get("/produtos/get",
          {
              headers: {
                  'this.state-Type': 'application/json',
                  'token': getToken()
              }
          });
      return response.data.data;
  } catch (err) {
      console.log(err)
      return 'ERRO';
  }
}

function achaItem(arrayProduto, produto){

  for( var item of arrayProduto){
      if(produto.C6_PRODUTO === item.B1_COD)
      return item.B1_XREDUZ;
  }

}

async function filtraPedidos(){
    //console.log("teste "+client.A1_COD)
    //const clientes = client;
    const pedidoCabeca = await requestCabeca();
    const pedidoItens = await requestItens();
    const produtos = await requestProdutos()
  
    const filtrado = [];

    const p = [];

    for(var pedido of pedidoCabeca){
        const fItens = [];
        //console.log("-----"+pedido.C5_NUM)
        if(pedido.C5_TPFLUIG === '0' && pedido.C5_NOTA === ''){
          console.log("----->>"+pedido.C5_NUM+"  --  "+pedido.C5_TPFLUIG)
        for(var item of pedidoItens){
         
            if(pedido.C5_NUM === item.C6_NUM){          
                const aux = {

                  C6_NUM: item.C6_NUM,
                  C6_ITEM: item.C6_ITEM,
                  C6_PRODUTO: item.C6_PRODUTO,
                  C6_QTDVEN: item.C6_QTDVEN,
                  C6_PRCVEN: item.C6_PRCVEN,
                  C6_VALOR: item.C6_VALOR,
                  ItemDesc: await achaItem(produtos, item)
  
                }
              fItens.push(aux);
            }
          
        }
        p.push(createData(pedido, fItens));
      }
    }
    //console.log(p[2].pedido)
    return p;
}

function createData(filtrado, itens) {

  console.log("----->>"+filtrado.C5_NUM+"  --  "+filtrado.C5_TPFLUIG)

  return {
    C5_VEND1 : filtrado.C5_VEND1,
    C5_TABELA: filtrado.C5_TABELA,
    C5_XFLGNUM: filtrado.C5_XFLGNUM,
    C5_CLIENTE: filtrado.C5_CLIENTE,
    C5_LOJACLI: filtrado.C5_LOJACLI,
    C5_CONDPAG: filtrado.C5_CONDPAG,
    pedido : filtrado.C5_NUM,
    emissao  :filtrado.C5_EMISSAO,
    datafat : filtrado.C5_XFLGFAT,
    nota : filtrado.C5_NOTA,
    valor : filtrado.F2_VALFAT,
    itens,
  };
}

function formataData(aData){

  try{

    var data = `${aData.substring(6)}/${aData.substring(4,6)}/${aData.substring(0,4)}`
    return data

  }catch{
    return 'Erro na Data'
  }

}

function Row(props){ 

  const { row } = props;
  const classes = useRowStyles();
  const[open, setOpen] = React.useState(false);


  function parentCallback(event){

      props.onClick(row, '2');
  }

  
return (
      <React.Fragment>
        <TableRow className={classes.root}>
          <TableCell>
            <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
          <TableCell component="th" scope="row">{row.pedido}</TableCell>
          <TableCell align="right">{formataData(row.emissao)}</TableCell>
          <TableCell align="right">{formataData(row.datafat)}</TableCell>
          <TableCell align="right">{row.nota}</TableCell>
          <TableCell align="right">{row.valor}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box margin={1}>
                <Typography variant="h6" gutterBottom component="div">
                  Itens
                </Typography>
                <Table size="small" aria-label="purchases">
                  <TableHead>
                    <TableRow>
                      <TableCell>Produto</TableCell>
                      <TableCell>Quantidade</TableCell>
                      <TableCell align="right">Preço de venda</TableCell>
                      <TableCell align="right">Preço total</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {row.itens.map((historyRow) => (                
                        <TableRow key={historyRow.C6_PRODUTO}>
                        <TableCell component="th" scope="row">
                          {historyRow.ItemDesc}
                        </TableCell>
                        <TableCell>{historyRow.C6_QTDVEN}</TableCell>
                        <TableCell align="right">{historyRow.C6_PRCVEN}</TableCell>
                        <TableCell align="right">{historyRow.C6_VALOR}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
                <button id = 'avanc-button' onClick= {parentCallback}>Selecionar</button>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
      )
}

export default function SelecionaPedido (props) {
  const [pedidos, setPedidos] = useState([]);
  const [pedidosFull, setPedidosFull] = useState([]);
  const [mensagem, setMensagem] = useState('Carregando. . .');
  useEffect(() => {
    let isMounted = true;
    (async () => {
      try {
        const pedidosResponse = await filtraPedidos();
        if (isMounted) {
          setPedidos(pedidosResponse);
          setPedidosFull(pedidosResponse);
        }
      }
      catch (e) {
        console.log('Error fetching pedidos: '+ e)
      }
    })();
    return () => {
      isMounted = false;
    }
  }, []);


  const handleFiltro = event =>{
    event.persist();
    let result = [];
    try{
      console.log("=====>"+pedidosFull.length)
    for (var eachAux of pedidosFull) {
      console.log('---->'+ eachAux.pedido)
        if (eachAux.pedido.includes(event.target.value.toUpperCase()) || eachAux.nota.includes(event.target.value.toUpperCase())) {
            result.push(eachAux);
        }
    }
    if(result.length === 0){
      setMensagem('Nenhum pedido encontrado.')
    }
    setPedidos(result)
   }catch(err){
    setMensagem('Erro ao buscar')
  }
  };

  function parentCallback(pedido, estagio){
    props.onClick(pedido,estagio);
  }

  return (
  <div>
    <div>
      <h3>Ultimos Pedidos:</h3>
      <hr/>
      <input type="text" id="filtro" enable={pedidosFull.length>0} onChange={handleFiltro} placeholder="Procurar cliente..."></input>
      </div>
      <div id="main-div">
        {
          (pedidos.length==0)?<h1>{mensagem}</h1>:
          <div>
          <TableContainer>
            <Table aria-label="collapsible table" id = 'saldos-table'>
            <TableHead>
              <TableRow>
               <TableCell />
               <TableCell>Pedido</TableCell>
               <TableCell align="right">Emissão</TableCell>
               <TableCell align="right">Data Faturamento</TableCell>
               <TableCell align="right">Nota</TableCell>
               <TableCell align="right">Valor</TableCell>
              </TableRow>
           </TableHead>
           <TableBody>
             {pedidos.map((row) => (<Row key={row.pedido} row={row} onClick ={parentCallback} />))}
           </TableBody>
          </Table>
          </TableContainer>
          </div>}
      </div>
      <ReactHTMLTableToExcel  
              className="btn btn-info"  
              table="saldos-table"  
              filename="ReportExcel"  
              sheet="Sheet"  
              buttonText="Export excel" />  
  
      </div>
  )

}