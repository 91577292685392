import './Nav.css'
import Logo from './Logo'
import React from 'react'
import {logout} from '../../services/login'
export default props => <aside className = 'menu-area'>
    <div id = 'logoNav'>
    <Logo />
    </div>
   <nav className="menu" id = 'navv'>
   <hr/>
       <a href = "/app"> 
           <i className="fa fa-home"></i> <strong>Início</strong>
       </a>
       <hr/>
       <a href="/app/pedidos">
           <i className = "fa fa-dollar" ></i> <strong>Novo Pedido</strong>
       </a>
       <hr/>
       {/*
           <a href="/app/alteracao">      
           <i className = "fa fa-pencil"></i> <strong>Alterar Pedido</strong>
       </a>
       <hr/>
       <a href="/app/saldos">      
           <i className = "fa fa-undo"></i> <strong>Controle de Estoque</strong>
       </a>
       <hr/>
       <a href="/app/novaverba">      
           <i className = "fa fa-credit-card"></i> <strong>Solicitação De Verba</strong>
       </a>
       <hr/>
       <a href="/app/monitorverbas">      
           <i className = "fa fa-check-square-o"></i> <strong>Monitor De Verbas</strong>
       </a>
       <hr/>
       */
       }
       
       <a href="/app/clientes">      
           <i className = "fa fa-user"></i> <strong>Cadastro de Clientes</strong>
       </a>
       <hr/>
       <a href="/app/canhotos">      
           <i className = "fa fa-camera"></i> <strong>Envio de Canhotos</strong>
       </a>
       <hr/>
       <a href="/app/devolucoes">      
           <i className = "fa fa-undo"></i> <strong>Solicitação De Devolução</strong>
       </a>
       <hr/>
       <a href="/" onClick = { e => logout()}>      
           <i className = "fa fa-sign-out"></i> <strong>Sair</strong>
       </a>
       <hr/>
   </nav>
</aside>