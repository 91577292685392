import React, { Component } from 'react'
import './DataeMsg.css'

export default class DataMsg extends Component {
    state = {
        msgNota: '',
        msgEqp: '',
        data: '',
        toStage: '4',
        dataMax: '', 
        dataMin: '',
    }
    handleVolta() {      
        this.props.parentCallback(this.state);
    }
    componentDidMount(){
        this.setState({dataMax: this.getDataMax()})
        this.setState({dataMin: this.getDataMin(), data:this.getDataMin()})
    }
    getDataMin(){
        var data;
        data = new Date();
        console.log(`${data.getFullYear()}-${(data.getMonth() +1)<10?"0"+(data.getMonth() +1):(data.getMonth() +1)}-${data.getDate()<10?"0"+data.getDate():data.getDate()}`)
        return (`${data.getFullYear()}-${(data.getMonth() +1)<10?"0"+(data.getMonth() +1):(data.getMonth() +1)}-${data.getDate()<10?"0"+data.getDate():data.getDate()}`);
    }
    getDataMax(){
        var data = new Date(Date.now()+1209600000);
        console.log(`${data.getFullYear()}-${(data.getMonth() +1)<10?"0"+(data.getMonth() +1):(data.getMonth() +1)}-${data.getDate()<10?"0"+data.getDate():data.getDate()}`)

        if(data.getDate()>26 || data.getMonth()>=6 )
        return(`2021-06-26`)

        return(`${data.getFullYear()}-${(data.getMonth() +1)<10?"0"+(data.getMonth() +1):(data.getMonth() +1)}-${data.getDate()<10?"0"+data.getDate():data.getDate()}`);
    }
    handleAvanco() {
        this.setState({ toStage: '4' });
    }

    //2021-06-07

    render() {
        return (
            <div id ="msg-main-div" className = 'dataForm'>
                    <h5>Selecione a data de faturamento:</h5>
                    <input type="date" required onChange={e => this.setState({ data: e.target.value })}
                        min = {this.state.dataMin} max = {this.state.dataMax}
                        value = {this.state.data}
                    ></input>
                    <hr />
                    <h5>A seguinte mensagem será impressa na nota fiscal:</h5>
                    <textarea name="msgNFE" onChange={e => this.setState({ msgNota: e.target.value })}></textarea>
                    <hr />
                    <h5>A seguinte mensagem será de uso exclusivo para a equipe interna:</h5>
                    <textarea name="msgEqp" onChange={e => this.setState({ msgEqp: e.target.value })}></textarea>
                    <div id = 'div-buttons'>
                        <div>
                        <button id = 'voltar-button' onClick={e => this.props.parentCallback({msgNfe:'', msgEqp: '', toStage:'1', dataFat: ''})}>Voltar</button>
                        </div>
                        <div>
                        <button id = 'avanc-button' type = 'submit' onClick={e => this.handleVolta()}>Avançar</button>
                        </div>
                    </div>
            </div>
        )
    }
}