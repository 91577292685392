import React, { Component } from 'react'
import Main from '../templates/Main'
import { getToken, getCat } from '../../services/login'
import api from '../../services/api'
import './Saldos.css'
import ReactHTMLTableToExcel from 'react-html-table-to-excel';  

const headerProps = {
    icon: 'undo',
    title: " Solicitação de Devolução"
}


export default class Devolucoes extends Component {

    state = {
        saldos: [],
        full_saldos: [],
        escolhido: '',
        Inicio: '',
        Fim:'',
    }

    componentDidMount() {
       
    }


    requestClientes = async e => {
        try {
            const response = await api.post("/movimentasaldo/post",{
                agente: "000830",
                dtini: this.state.Inicio,
                dtfinal: this.state.Fim
            },
                {
                    headers: {
                        'this.state-Type': 'application/json',
                        'token': getToken()
                    }
                });

            //this.setState({ saldos: response.data.data, full_saldos: response.data.data });
            this.addFooLine(response.data.data)
        } catch (err) {
            console.log(err)
       
        }
    }

    getEscolhido() {
        return this.state.escolhido;
    }

    makeFooLine(entrada, saida){
        const foo = {
            PEDIDO: '---',
            F_EMISSAO: '---',
            TIPO_MOVIMENTACAO: '---',
            SEQ_MOV: '---',
            B1_XREDUZ: '---',
            QTDENTRADA_UNIDADE: entrada,
            QTDSAIDA_UNIDADE: saida,
            SALDO: (entrada-saida),
            NF_VENDA: '---',
            NOTA_MOVIMENTO: '---',
            TIPO_MOVIMENTACAO: '---',
            F_EMISSAO: '---'
        }

        return foo;
    }


    async addFooLine(saldos){
        try{
        var flag = saldos[0].COD_PRODUTO;
        var cont = -1;
        var arr = saldos;
        var entrada = 0;
        var saida = 0;
        for(var aux of saldos){
            cont ++;
            
            if( flag !== aux.COD_PRODUTO){
               
                arr.splice(cont,0, await this.makeFooLine(entrada,saida));
                flag = aux.COD_PRODUTO;
                entrada = 0;
                saida = 0;
            }
            else{
                entrada += aux.QTDENTRADA_UNIDADE;
                saida += aux.QTDSAIDA_UNIDADE;}
            console.log('valor do entrada: '+ entrada)
        }
        this.setState({saldos:arr})
    }catch(err){
        console.log('erro')
        }
    }
    

    renderRow() {
        return this.state.saldos.map(saldo => {
            return (
                <tr key={`${saldo.PEDIDO}-${saldo.COD_PRODUTO}-${saldo.F_EMISSAO}`} className = {saldo.TIPO_MOVIMENTACAO === 'REMESSA'?'verde':'vermelho'}>
                    <td>{saldo.SEQ_MOV}</td>
                    <td>{saldo.COD_PRODUTO}</td>
                    <td>{saldo.B1_XREDUZ}</td>
                    <td>{saldo.QTDENTRADA_UNIDADE}</td>
                    <td>{saldo.QTDSAIDA_UNIDADE}</td>
                    <td>{saldo.SALDO}</td>
                    <td>{saldo.NF_VENDA}</td>
                    <td>{saldo.NOTA_MOVIMENTO}</td>
                    <td>{saldo.TIPO_MOVIMENTACAO}</td>
                    <td>{saldo.F_EMISSAO}</td>
                </tr>
            )
        })
    }

    renderTable() {
        return (
            <table id="saldos-table">
                <thead>
                    <tr className="header">
                        <th>Seq.</th>
                        <th>Cod. Produto</th>
                        <th>Produto</th>
                        <th>Entrada</th>
                        <th>Saida</th>
                        <th>Saldo</th>
                        <th>Nf Venda</th>
                        <th>Nf Mov.</th>
                        <th>Tipo Mov.</th>
                        <th>Data</th>
                    </tr>
                </thead>
                <tbody>
                    {this.renderRow()}
                </tbody>
            </table>
        )
    }
    
    handleClick = e =>{
       // e.preventDefault();
        this.requestClientes()
    }

    reviseContent(strg){
        return strg.normalize('NFD').replace(/[\u0300-\u036f]/g, "").replace(/[^a-zA-Z0-9 \n]/g, "").toUpperCase();
        }   

 
    render() {
        return(
            <Main {...headerProps}>
                <div className = 'vbCol'>
                    <div className = 'impTtl'>
                    <h6>Data Inicial</h6>
                    <input placeholder='Negociado de' type='date' maxLength="60"
                     onChange={e => this.setState({ Inicio: this.reviseContent(e.target.value) })}
                    ></input>
                    </div>
                    <div className = 'impTtl'>
                    <h6>Data final</h6>
                    <input placeholder='Negociado até' type='date' maxLength="60"
                    disabled = {this.state.Inicio===''}
                    min = {this.state.Inicio} 
                    onChange={e => this.setState({ Fim: this.reviseContent(e.target.value) })} ></input>
                    </div>
                    
                        <button onClick={ e => this.handleClick()}>Busca</button>
                    
                    </div>
                    <br></br>
              <div id="main-div">
                    {this.state.saldos===''?<h1 >Carregando...</h1>:this.renderTable()}
            </div>
            <br></br>
            <ReactHTMLTableToExcel  
              className="btn btn-info"  
              table="saldos-table"  
              filename="ReportExcel"  
              sheet="Sheet"  
              buttonText="Export excel" />  
            </Main>
        )
    }
}