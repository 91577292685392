import React, { Component } from 'react'
import { getToken } from '../../../services/login'
import api from '../../../services/api'
import './Escolha.css'



export default class Escolha extends Component {

    state = {
        clientes: [],
        full_clientes: [],
        escolhido: ''
    }

    componentDidMount() {
            this.requestClientes();
    }

    requestClientes = async e => {
        try {
            const response = await api.get("/clientes/get",
                {
                    headers: {
                        'this.state-Type': 'application/json',
                        'token': getToken()
                    }
                });
            this.setState({ clientes: response.data.data, full_clientes: response.data.data });
        } catch (err) {
            console.log(err)
        const pkg = {data:'', stage: 'Erro'}
        this.props.parentCallback(pkg);
        }
    }

    getEscolhido() {
        return this.state.escolhido;
    }

    sendData = escolhido => {
        console.log(escolhido.A1_RISCO);
        if(escolhido.A1_RISCO === 'E'){
            const pkg = {data: escolhido, stage: '7'}
            this.props.parentCallback(pkg);
        }else if(escolhido.A1_MSBLQL === 'Inativo'){

            const pkg = {data: escolhido, stage: '8'}
            this.props.parentCallback(pkg);

        }else{
        const pkg = {data: escolhido, stage: '2'}
        this.props.parentCallback(pkg);
        }
    }

    renderRow() {
        return this.state.clientes.map(cliente => {
            return (
                <tr key={`${cliente.A1_COD}-${cliente.A1_LOJA}`} onClick={e => { this.sendData(cliente) }}>
                    <td>{cliente.A1_NOME}</td>
                    <td>{cliente.A1_CGC}</td>
                    <td>{`${cliente.A1_COD}-${cliente.A1_LOJA}`}</td>
                    <td>{cliente.A1_MUN}</td>
                </tr>
            )
        })
    }

    renderTable() {
        return (
            <table id="clientes-table">
                <thead>
                    <tr className="header">
                        <th>Nome</th>
                        <th>CNPJ</th>
                        <th>Cod-Loja</th>
                        <th>Cidade</th>
                    </tr>
                </thead>
                <tbody>
                    {this.renderRow()}
                </tbody>
            </table>
        )
    }

    filtro = item => {
        let list = this.state.full_clientes;
        let result = [];
        for (const aux of list) {
            if (aux.A1_NREDUZ.includes(item.toUpperCase()) || aux.A1_CGC.includes(item.toUpperCase()) 
            || aux.A1_MUN.includes(item.toUpperCase()) || aux.A1_COD.includes(item.toUpperCase()) ) {
                result.push(aux);
            }
        }
        this.setState({ clientes: result })
    }

    render() {
        return (
            <div>
                <div>
                <h3>Escolha um cliente:</h3>
                <hr/>
                <input type="text" id="filtro" onChange={e => this.filtro(e.target.value)} placeholder="Procurar cliente..."></input>
                </div>
                <div id="main-div">
                    {this.state.clientes===''?<h1 >Carregando...</h1>:this.renderTable()}
                </div>
            </div>
        )
    }
}