import React, { Component } from 'react'
import { getToken } from '../../../services/login'
import api from '../../../services/api'
import { getCod, getCat} from '../../../services/login'
import './Finaliza.css'

export default class Finaliza extends Component{

    /*
     private class RequestBody {

            String pedido;
            String cliente;
            String loja;
            String vendedor;
            String pagamento;
            String tabela;
            String categoria;
            String dtfaturamento;
            String msg_nota;
            String msg_obs;
            String atendimento;
            ArrayList<Itens> data;

            class Itens {
                String produto;
                double quantidade;
                double preco;

                Itens(TabPI1 itens) {

                    this.produto = itens.CodigoProduto;
                    this.quantidade = itens.Quantidade;
                    this.preco = itens.PrecoUnitario;
                }
            }

             state = {
        escolhido: '',
        estagio: '1',
        msgNota:'',
        msgEqp:'',
        dataFat:'',
        modus:'',
        data:[]
    }


    */

    state = {
        pedido:'',
        cliente:'',
        loja:'',
        vendedor:'',
        pagamento:'',
        tabela:'',
        categoria:'',
        dtfaturamento:'',
        msg_nota:'',
        msg_obs:'',
        atendimento:'',
        data:[]
    }
    nPedidoGen(){
        var data = new Date()
        var p1, p2, p3;
        p1 = (`${data.getFullYear()}${('0'+(data.getMonth()+1)).slice(-2)}${("0" + data.getDate()).slice(-2)}`);
        p2 = (`${("0" + (new Date().getHours())).slice(-2)}${("0" + (new Date().getMinutes())).slice(-2)}${("0" + (new Date().getSeconds())).slice(-2)}`)
        p3 = getCod();
        console.log(p1+p2+p3)
        return(p1+p2+p3)
    }

    componentDidMount(){
        this.setState({ 
            pedido: this.nPedidoGen(),
            cliente: this.props.dataFromParent.escolhido.A1_COD,
            loja: this.props.dataFromParent.escolhido.A1_LOJA,
            vendedor: getCod(),
            pagamento: this.props.dataFromParent.escolhido.A1_COND,
            tabela: this.props.dataFromParent.escolhido.A1_TABELA,
            categoria: getCat(),
            origem:'W',
            dtfaturamento: this.reviseContent(this.props.dataFromParent.dataFat),
            msg_nota: this.reviseContent(this.props.dataFromParent.msgNota),
            msg_obs: this.reviseContent(this.props.dataFromParent.msgEqp),
            atendimento: this.props.dataFromParent.escolhido.A1_XGC,
            data: this.props.dataFromParent.data,
        })
    }

    reviseContent(strg){
        return strg.normalize('NFD').replace(/[\u0300-\u036f]/g, "").replace(/[^a-zA-Z0-9 \n]/g, "").toUpperCase();
    }

    handleSubmit = async e => {

        console.log("JSON:  " + JSON.stringify(this.state));
        console.log("Teste" + getToken() + "|");
        try {
            const response = await api.post("/pedidos/post", this.state, {
                headers: {
                    'this.state-Type': 'application/json',
                    'token': getToken()
                }
            });
            console.log(response.data);
            console.log(response.status);
            this.props.parentCallback('6');
        }
        catch (err) {
            console.error("erro no handle: " + err.stack)
            this.props.parentCallback('Erro');
        }
    }

    renderTable(){
        return(
            <table id="final-table">
                <thead>
                    <tr>
                        <th>Produto</th>
                        <th>Quantidade</th>
                        <th>Preço (UN)</th>
                        <th>Preço (Total)</th>
                    </tr>
                </thead>
                <tbody>
                    {this.renderRow()}
                </tbody>
            </table>
        )
    }

    renderRow(){
        return this.state.data.map(itens => {
            return (
                <tr key={itens.produto}>
                    <td>{this.props.dataFromParent.nMap.get(itens.produto)}</td>
                    <td>{itens.quantidade}</td>
                    <td>{itens.preco}</td>
                    <td>{itens.preco*itens.quantidade}</td>
                </tr>
            )
        })
    }
    calculaTotal(){
        let total = 0;
        for(var it of this.state.data){
            total+=(it.quantidade*it.preco)
        }
        return total
    }

    render(){
        return (
            <div>
            <h3>Por favor, confira o pedido antes de enviar!</h3>
            <hr/>
            <div id ="finaltable-div">
            {this.renderTable()}
            </div>
            <div>
            <h5>{`Total do pedido: R$${this.calculaTotal()}`}</h5>
            </div>
            <hr/>
            <button id='submit-button' onClick={ e=> this.handleSubmit()}>Enviar Pedido</button>
            </div>
        )
    }
}