
import React from 'react'
import './Resposta.css'

export default props =>
<div>
        <div className="display-4">Erro! :(</div>
        <hr />
        <div id= 'msg-div'>
        <p id = "resp-msg" className="mb-0">Um erro inesperado ocorreu ao tentar comunicar com o servidor, por favor, tente novamente!</p>
        </div>
        <hr/>
        <a href='/app'><button id='ok-button'>Ok!</button></a>
</div>