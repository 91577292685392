import React from 'react'
import './Resposta.css'

export default props =>
<div>
        <div className="display-4">Atenção!</div>
        <hr />
        <div id= 'msg-div'>
        <p id = "resp-msg" className="mb-0">O cliente selecionado encontra-se no risco 'E', e não pode fazer novos pedidos!</p>
        <br/><br/>
        <p>Por favor, entre em contato com o financeiro para regularizar a situação.</p>
        </div>
        <hr/>
        <a href='/app/pedidos'><button id='ok-button'>Ok!</button></a>
</div>