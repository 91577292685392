import React, { Component } from 'react'
import Main from '../templates/Main'
import { getCat } from '../../services/login'
import SelecionaPedido from './selecionaPedido/SelecionaPedido'
import MudaData from './mudaData/MudaData'
import AlteraPedido from './alteraPedido/AlteraPedido'
import Sucesso from './respostas/Sucesso'
import EnviaAlteracao from './enviaAlteracao/EnviaAlteracao'
import Erro from './respostas/Erro'


const headerProps = {
    icon: 'dollar',
    title: " Novo Pedido"
}

export default class Pedido extends Component {

    state = {
        escolhido: '',
        estagio: '1',
        msgNota:'',
        msgEqp:'',
        dataFat:'',
        modus:'',
        nMap: new Map(),
        data:[]
    }

    callbackEscolha = (pedido, estagio ) => {
        this.setState({ escolhido: pedido})
        this.setState({ estagio: estagio })
    }

    callbackResposta = (childData) => {
        this.setState({estagio: childData})
    }

    callbackMsg = (childData ) =>{
        console.log('mensagem1: '+childData.msgNota+' Mensagem 2: '+childData.msgEqp+"estagio: "+childData.toStage+"data"+childData.data)
        this.setState({msgNota: childData.msgNota, msgEqp: childData.msgEqp, estagio:childData.toStage, dataFat: childData.data})
        //this.setModus(childData.data);
    }

    callbackFinaliza=(childData)=>{
        this.setState({data:childData.itens});
        this.setState({nMap:childData.nomes});
        this.setState({estagio: childData.stage});

    }

    conditionalRender() {
        if (this.state.estagio === '1') {
            return (
                <SelecionaPedido onClick={this.callbackEscolha} >
                </SelecionaPedido>
            )
    }   else if(this.state.estagio ==='2'){
        return(
            <MudaData onClick = {this.callbackMsg}>

            </MudaData>
        )
    }   else if(this.state.estagio ==='3'){
        return(
            <AlteraPedido onClick = {this.callbackFinaliza}  dataFromParent = {this.state} >

            </AlteraPedido>
        )
    }   else if(this.state.estagio ==='4'){
        return(
            <EnviaAlteracao dataFromParent = {this.state} parentCallBack = {this.callbackResposta}></EnviaAlteracao>
        )
    }
        else if(this.state.estagio ==='5'){
        return(
            <Sucesso></Sucesso>
        )
    }
    else{
        return(
            <Erro></Erro>
        )
    }
}

    render() {
        return (
            <Main {...headerProps}>
                {this.conditionalRender()}
            </Main>
        );
    }

}
