import React, { Component } from 'react'
import Main from '../templates/Main'
import { getToken, getCat } from '../../services/login'
import api from '../../services/api'
import './Devolucoes.css'
import Erro from './Erro'
import Sucesso from './Sucesso'

const headerProps = {
    icon: 'undo',
    title: " Solicitação de Devolução"
}


export default class Devolucoes extends Component {

    state = {
        notas: [],
        full_notas: [],
        data: null,
        escolhido: null,
        render: 99,
        tipoDev: null,
        numero_nota:'',
        valor_nota:'',
        motivo_dev:'',
        serie_nota:'',
        itens:null,
        itens_escolhidos:null,
        detalhes_cancelamento:'',
        status: null,
        status_escolhido:null,
        flag_valor:true,
        flag_nNota:true,
        flag_motivo:true,
        flag_serie:true,
        flag_detalhe:true,
    }

    componentDidMount() {
            this.requestNotas();
            this.requestItens();
            
            this.setState({data: this.getDataMin()})
    }

    getDataMin(){
        var data;
        data = new Date();
        console.log(`${data.getFullYear()}-${(data.getMonth() +1)<10?"0"+(data.getMonth() +1):(data.getMonth() +1)}-${data.getDate()<10?"0"+data.getDate():data.getDate()}`)
        return (`${data.getFullYear()}-${(data.getMonth() +1)<10?"0"+(data.getMonth() +1):(data.getMonth() +1)}-${data.getDate()<10?"0"+data.getDate():data.getDate()}`);
    }


    verificaTudo(){
        console.log('Entrou1')
        return (this.state.tipoDev === 'DVT'? this.verificaCamposDVT():this.verificaCamposDVP());

    }


    handleSubmit = async e =>{

        if(this.verificaTudo()){
            console.log('Entrou2')
        const requestBody = {
            tipo:this.state.tipoDev,
            pedido:this.state.escolhido.C5_XFLGNUM,
            cliente:this.state.escolhido.C5_CLIENTE,
            loja:this.state.escolhido.C5_LOJACLI,
            vendedor:this.state.escolhido.C5_VEND1,
            pagamento:this.state.escolhido.C5_CONDPAG,
            tabela:this.state.escolhido.C5_TABELA,
            categoria: getCat(),
            dtfaturamento:this.state.escolhido.C5_XFLGFAT,
            pedprotheus:this.state.escolhido.C5_NUM,
            detalhedevolucao:this.state.detalhes_cancelamento.normalize('NFD').replace(/[\u0300-\u036f]/g, "").replace(/[^a-zA-Z0-9 \n]/g, "").toUpperCase(),
            motivodevolucao:this.state.motivo_dev,
            notasubstituta:this.state.numero_nota,
            serienotasubstituta:this.state.serie_nota,
            data: this.state.itens_escolhidos,
            msg_nota : this.state.escolhido.C5_MENNOTA,
            msg_obs : this.state.escolhido.C5_XOBSPOR,
            horafaturamento: this.state.status_escolhido
            
        }

        console.log("Teste" + JSON.stringify(requestBody) + "|");
        try {
            const response = await api.post("/pedidos/post", requestBody, {
                headers: {
                    'this.state-Type': 'application/json',
                    'token': getToken()
                }
            });
            console.log(response.data);
            console.log(response.status);
            if(response.status === 200){
                this.setState({render:'10'})
                console.log('deu bom')
            }
            else
            this.setState({render: '7'});
            console.log('deu ruim')
        }
        catch (err) {
            console.error("erro no handle: " + err.stack)
            this.setState({render: '7'});
            
        }
    }
    else{

    }
    
    }


    requestNotas = async e => {
        try {
            const response = await api.get("/pedidos/get_cabecalho",
                {
                    headers: {
                        'this.state-Type': 'application/json',
                        'token': getToken()
                    }
                });
            this.setState({ notas: response.data.data, full_notas: response.data.data });
        } catch (err) {
            console.log(err)
    
        }

        this.requestStatus();
    }

    requestStatus = async e => {
        try {
            const response = await api.get("/pedidos/get_status",
                {
                    headers: {
                        'this.state-Type': 'application/json',
                        'token': getToken()
                    }
                });
            this.setState({ status: response.data.data });
        } catch (err) {
            console.log(err)
    
        }

        this.organizaLista();
    }

    organizaLista = async e =>{

        const lista = await this.selectPossivel()
        this.setState({notas:lista, full_notas:lista, render:0});
    }

    selectPossivel(){
        const filtrado = [];
        for( var obj of this.state.notas){
            //console.log('ta entrando')
            for(var comp of this.state.status){
                //console.log('comparando' + obj.C5_NUM + '==' + comp.C5_NUM)
                if(obj.C5_NUM === comp.C5_NUM){
                    //console.log("comparando o seguinte status "+ comp.C5_Q_STAT)
                    if(comp.C5_Q_STAT!=='5' && comp.C5_Q_STAT!=='6' && !comp.C5_Q_STAT!=='8' && obj.C5_TPFLUIG === '4'){
                        //console.log("comparando o seguinte status "+ comp.C5_TPFLUIG)
                            filtrado.push(obj) 
                        
                    }
                    
                }
            }
        }
        return filtrado;
    }

    
    requestItens = async e => {
        try {
            const response = await api.get("/pedidos/get_itens",
                {
                    headers: {
                        'this.state-Type': 'application/json',
                        'token': getToken()
                    }
                });
            this.setState({ itens: response.data.data });
        } catch (err) {
            console.log(err)
    
        }
    }

    getEscolhido() {
        return this.state.escolhido;
    }

    sendData = _escolhido => {
        
        console.log(_escolhido.C5_NUM);
        this.setState({escolhido:_escolhido, render:1})
        this.selecionaItens(_escolhido.C5_NUM);
        this.selecionaHora(_escolhido.C5_NUM);
        
    }

    selecionaItens(esc){

        var npedido = esc;
        let lista = [];
        for (var obj of this.state.itens){
            if(obj.C6_NUM===npedido){
                
                var dat ={
                    produto: obj.C6_PRODUTO,
                    quantidade: obj.C6_QTDVEN,
                    preco: obj.C6_PRCVEN,
                    deleta: 'N'
                }
                
                
                lista.push(dat);
            }
        }
        this.setState({itens_escolhidos:lista});
    }

    selecionaHora(esc){
        
        for (var obj of this.state.status){
            if(obj.C5_NUM === esc){

                this.setState({status_escolhido: obj.F2_HORA});

            }
        }

    }

    renderRow() {
        console.log('a'+this.state.notas.length)
        return this.state.notas.map(nota => {
            return (
                <tr key={`${nota.C5_NOTA}`} onClick={e => { this.sendData(nota) }}>
                     <td>{nota.C5_NOTA}</td>
                        <td>{nota.C5_NUM}</td>
                    <td>{`${nota.C5_EMISSAO.substring(6)}/${nota.C5_EMISSAO.substring(4,6)}/${nota.C5_EMISSAO.substring(0,4)}`}</td>
                    <td>{`${nota.C5_CLIENTE}-${nota.C5_LOJACLI}`}</td>
                </tr>
            )
        })
    }

    renderTable() {
        return (
            <table id="notas-table">
                <thead>
                    <tr className="header">
                        <th>Número da Nota</th>
                        <th>Número do Pedido</th>
                        <th>Data de Emissão</th>
                        <th>Cod. Cliente - Loja</th>
                    </tr>
                </thead>
                <tbody>
                    {this.renderRow()}
                </tbody>
            </table>
        )
    }

    filtro = item => {
        let list = this.state.full_notas;
        let result = [];
        for (const aux of list) {
            if (aux.C5_NOTA.includes(item.toUpperCase()) || aux.C5_NUM.includes(item.toUpperCase()) 
            || aux.C5_CLIENTE.includes(item.toUpperCase()) || aux.C5_LOJACLI.includes(item.toUpperCase()) ) {
                result.push(aux);
            }
        }
        this.setState({ notas: result })
    }

    conditionalRender(){
        if(this.state.render === 99)
        {
            return (
                <div className = 'dataForm'>
                    <div>
                    <h3>Selecione uma nota:</h3>
                    <hr/>
                    <input type="text" id="filtro"  placeholder="Procurar nota..."></input>
                    </div>
                    <div id="main-div">
                        <h1 >Carregando...</h1>
                    </div>
                </div>
            )
    
        }
        else if(this.state.render===0)
        return (
            <div className = 'dataForm'>
                <div>
                <h3>Selecione uma nota:</h3>
                <hr/>
                <input type="text" id="filtro" onChange={e => this.filtro(e.target.value)} placeholder="Procurar nota..."></input>
                </div>
                <div id="main-div">
                    {this.state.status===null?<h1 >Carregando...</h1>:this.renderTable()}
                </div>
            </div>
        )

        else if (this.state.render === 1){
            return (
                <div className = 'dataForm'>
                    <div>
                        <h5><strong>NF Selecionada: </strong></h5>
                        <h5>{this.state.escolhido.C5_SERIE}- {this.state.escolhido.C5_NOTA}</h5>
                        <br></br>
                        <h5><strong>Valor da NF: </strong></h5>
                        <h5>{this.state.escolhido.F2_VALFAT}</h5>
                        <hr></hr>
                        <h5>Escolha o tipo de Devolução:</h5>
                        <select onChange={e => this.setState({ tipoDev: e.target.value, render : 2})} >
                            <option defaultValue value="00">Selecione</option>
                            <option value="DVT">Devolução Total</option>
                            <option value="DVP">Devolução Parcial</option>
                        </select>
                    </div>
                </div>
            )

            //<!-- accept="image/jpeg" -->
        } else if (this.state.render === 2){
            return (
                <div className = 'dataForm'>
                    <div>
                        <h5><strong>NF Selecionada: </strong></h5>
                        <h5>{this.state.escolhido.C5_SERIE}- {this.state.escolhido.C5_NOTA}</h5>
                        <br></br>
                        <h5><strong>Valor da NF: </strong></h5>
                        <h5>{this.state.escolhido.F2_VALFAT}</h5>
                        <hr></hr>
                        <div className="devForm" >{this.renderDVPouDVT()}</div>
                    </div>
                </div>
            )
        }else if (this.state.render ==='7'){
            return(<Erro></Erro>)
        }else if (this.state.render ==='10'){
            return(<Sucesso></Sucesso>)
        }
    }

    verificaCamposDVT(){

        var flag = true;
        console.log('Entrou0')
        if(this.state.numero_nota === '' || this.state.numero_nota !== this.state.escolhido.C5_NOTA){
            this.setState({flag_nNota:false});
            flag = false;
            console.log('Entrou01')
        }else{
            this.setState({flag_nNota:true});
        }if(this.state.valor_nota === '' || this.state.valor_nota != this.state.escolhido.F2_VALFAT){
            this.setState({flag_valor:false});
            flag = false;
            console.log('Entrou02')
        }else{
            this.setState({flag_valor:true});
        }if(this.state.motivo_dev === '00'){
            this.setState({flag_motivo:false});
            flag = false;
            console.log('Entrou03')
        }else{
            this.setState({flag_motivo:true});
        }if(this.state.detalhes_cancelamento ===''){
            this.setState({flag_detalhe:false});
            flag = false;
            console.log('Entrou04')
        }else{
            this.setState({flag_detalhe:true});
        }

        return flag;

    }

    verificaCamposDVP(){

        var flag = true;

        if(this.state.numero_nota === ''){
            this.setState({flag_nNota:false});
            flag = false;
        }else{
            this.setState({flag_nNota:true});
        }
        if(this.state.valor_nota === ''){
            this.setState({flag_valor:false});
            flag = false;
        }else{
            this.setState({flag_valor:true});
        }
        if(this.state.motivo_dev === '00'){
            this.setState({flag_motivo:false});
            flag = false;
        }else{
            this.setState({flag_motivo:true});
        }
        if(this.state.serie_nota === ''){
            this.setState({flag_serie:false});
            flag = false;
        }else{
            this.setState({flag_serie:true});
        }
        if(this.state.detalhes_cancelamento ===''){
            this.setState({flag_detalhe:false});
            flag = false;
        }else{
            this.setState({flag_detalhe:true});
        }

        return flag;

    }
    

    renderDVPouDVT(){
        if(this.state.tipoDev === 'DVT'){
            return(
                <div className="devol">
                    <h5>Numero da NF:</h5>
                    <input placeholder='Numero da Nota' type='text' required maxLength="9" onChange={e => this.setState({ numero_nota: e.target.value })} ></input>
                    <p>{this.state.flag_nNota?'':"Atenção, verifique se o valor preenchido está correto"}</p>
                    <h5>Valor da Nota:</h5>
                    <input placeholder='Valor da Nota' required type='text' onChange={e => this.setState({ valor_nota: e.target.value })} ></input>
                    <p>{this.state.flag_valor?'':"Atenção, verifique se o valor preenchido está correto"}</p>
                    <h5>Motivos da devolução:</h5>
                    <select onChange={e => this.setState({ motivo_dev: e.target.value, render : 2})} >
                            <option defaultValue value="00">Selecione</option>
                            <option value="1101">Pedido em desacordo</option>
                            <option value="1102">Pedido em duplicidade</option>
                            <option value="1103">Sem Pedido</option>
                            <option value="1105">Divergencia de preço</option>
                            <option value="1301">Falta de mercadoria</option>
                            <option value="1302">Inversao de produto</option>
                            <option value="1303">Produto avariado/Mas condiçoes</option>
                            <option value="1501">Excesso de estoque</option>
                            <option value="1503">Falhas de sistema</option>
                    </select>
                    <p>{this.state.flag_motivo?'':"É necessário escolher um motivo!"}</p>
                    <h5>Detalhes do motivo de devolução:</h5>
                    <input placeholder='Detalhes do motivo de devolução' type='text' maxLength = {140}
                    required onChange={e => this.setState({ detalhes_cancelamento: e.target.value })} ></input>
                    <p>{this.state.flag_detalhe?'':"Atenção, é necessário detalhar o motivo da devolução"}</p>
                    <button  onClick={this.handleSubmit}>Salvar</button>
                </div>
            )
        }
        else if(this.state.tipoDev === 'DVP'){
            return(
                <div className="devol" >
                    <h5>Serie da NF de devolução:</h5>
                    <input placeholder='Serie da Nota' required type='text' maxLength="3" onChange={e => this.setState({ serie_nota: e.target.value })} ></input>
                    <p>{this.state.flag_serie?'':"Atenção, é necessário preencher um valor"}</p>
                    <h5>Numero da NF de devolução:</h5>
                    <input placeholder='Numero da Nota' type='text'required maxLength="9" onChange={e => this.setState({ numero_nota: e.target.value })} ></input>
                    <p>{this.state.flag_nNota?'':"Atenção, é necessário preencher um valor"}</p>
                    <h5>Valor da Nota de devolução:</h5>
                    <input placeholder='Valor da Nota' type='number'required onChange={e => this.setState({ valor_nota: e.target.value })} ></input>
                    <p>{this.state.flag_valor?'':"Atenção, é necessário preencher um valor"}</p>
                    <h5>Motivos da devolução:</h5>
                    <select onChange={e => this.setState({ motivo_dev: e.target.value, render : 2})} >
                            <option defaultValue value="00">Selecione</option>
                            <option value="1101">Pedido em desacordo</option>
                            <option value="1102">Pedido em duplicidade</option>
                            <option value="1103">Sem Pedido</option>
                            <option value="1105">Divergencia de preço</option>
                            <option value="1301">Falta de mercadoria</option>
                            <option value="1302">Inversao de produto</option>
                            <option value="1303">Produto avariado/Mas condiçoes</option>
                            <option value="1501">Excesso de estoque</option>
                            <option value="1503">Falhas de sistema</option>
                    </select>
                    <p>{this.state.flag_motivo?'':"É necessário escolher um motivo!"}</p>
                    <br></br>
                    <h5>Detalhes do motivo de devolução:</h5>
                    <input placeholder='Detalhes do motivo' type='text' maxLength = {140}
                    required onChange={e => this.setState({ detalhes_cancelamento: e.target.value })} ></input>
                    <p>{this.state.flag_detalhe?'':"Atenção, é necessário detalhar o motivo da devolução"}</p>
                    <button onClick={this.handleSubmit} >Salvar</button>
                </div>
            )
        }
        else{
            return(
                <div>
                    <h2>Ocorreu um erro! Por favor, tente novamente.</h2>
                </div>
            )
        }

    }

  

    render() {
        return(
            <Main {...headerProps}>
            {this.conditionalRender()}
            </Main>
        )
    }
}