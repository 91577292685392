import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import Logo from '../assets/imgs/logo.png';
import api from "../services/api";
import { login, TOKEN_KEY } from "../services/login";
import './index.css'



class SignIn extends Component {
  state = {
    email_: "",
    password_: "",
    error: ""
  };


  handleSignIn = async e => {
    localStorage.getItem(TOKEN_KEY);
     e.preventDefault();
     const { email_, password_ } = this.state;
     if (!email_ || !password_) {
       this.setState({ error: "Preencha e-mail e senha para continuar!" });
     } else {
       try {
         const response = await api.post("/vendedores/post", { email: email_, password: password_ });
         console.log(response.data);
         console.log(response.status);
         console.log(response.data.token);
         login(response.data.token, response.data.data[0].A3_NREDUZ, response.data.data[0].A3_EMAIL, response.data.data[0].A3_XCATEG, 
             response.data.data[0].A3_COD, response.data.data[0].A3_SUPER, response.data.data[0].A3_GEREN,response.data.data[0].MENSAGEM);
         this.props.history.push("/app");
       } catch (err) {
         console.log(err.stack);
         this.setState({
           error:
             "Houve um problema com o login, verifique suas credenciais."
         });
     }
    }
   };

  render() {
    return (
      <div className = "container">
        <form  className = "form" onSubmit={this.handleSignIn}>
          <img src={Logo} alt="Sitio logo" />
          {this.state.error && <p>{this.state.error}</p>}
          <input
            type="email"
            placeholder="Endereço de e-mail"
            onChange={e => this.setState({ email_: e.target.value })}
          />
          <input
            type="password"
            placeholder="Senha"
            onChange={e => this.setState({ password_: e.target.value })}
          />
          <button type="submit">Entrar</button>
          
          <hr />
        </form>
      </div>
    );
  }
}

export default withRouter(SignIn);