
import React, { Component } from 'react'
import { getToken } from '../../../services/login'
import api from '../../../services/api'
import './IncluiPedido.css'

export default class Pedido extends Component {

    /*
    importante sobre o cliente
    "A1_TABELA": "FLG",


    /produtos/get
    "B1_COD": "0101010007",
    "B1_UM": "KG",
    "B1_XREDUZ": "RELIQUIA 50 GR CX 20 KG",
    "B1_PESOCX": 20

    /preco/get
    "DA1_CODTAB": "010",
    "DA1_CODPRO": "0101010001",
    "DA1_DATVIG": "20160728",
    "DA1_PRCVEN": 64.36,
    "DA1_XDESMA": 20,
    "DA1_XDESMI": 20,
    "A1_VEND": "000745"

    /saldos/get
    "A1_VEND": "000745",
    "B6_PRODUTO": "0101010001",
    "B6_SALDO": 45

      private class RequestBody {

            String pedido;
            String cliente;
            String loja;
            String vendedor;
            String pagamento;
            String tabela;
            String categoria;
            String dtfaturamento;
            String msg_nota;
            String msg_obs;
            String atendimento;
            ArrayList<Itens> data;

            class Itens {
                String produto;
                double quantidade;
                double preco;

                Itens(TabPI1 itens) {

                    this.produto = itens.CodigoProduto;
                    this.quantidade = itens.Quantidade;
                    this.preco = itens.PrecoUnitario;
                }
            }
    */

    state = {
        cliente: '',
        produtos: [], // /produtos/get
        precos: [],   // /precos/get
        saldos: [],   // /saldos/get
        itensAExibir: [],
        itensDoPedido: [],
        selecionado: '',
        pMap: new Map(),
        qMap: new Map(),
        nMap: new Map(),
        vpMap:new Map(),
        vqMap:new Map(),
        data:'',
        erro: false
    }
    
    filtraProdutoPorSaldo(produtos) {
        //console.log("----->Tamanho do produto filtro 2(antes): " + produtos);
        let filtrado = [];
        for (var aux of produtos) {
            for (var aux2 of this.state.saldos) {
                if (aux.B1_COD === aux2.B6_PRODUTO)
                    filtrado.push(aux)
            }
        }
        //console.log("----->Tamanho do produto filtro 2: " + JSON.stringify(filtrado));
        this.setState({ produtos: filtrado });
    }

    filtraProdutoDaTabela(produtos) {
        //console.log("----->Tamanho do produto filtro 1(antes): " + JSON.stringify(produtos));
        let filtrado = [];
        for (var aux of produtos) {
            for (var aux2 of this.state.precos) {
                if (aux.B1_COD === aux2.DA1_CODPRO)
                    filtrado.push(aux)
            }
        }
        //console.log("----->Tamanho do produto filtro 1: " + JSON.stringify(filtrado));
        this.filtraProdutoPorSaldo(filtrado);
    }

    filtro = item => {
        return item.DA1_CODTAB === this.state.cliente.A1_TABELA;
    }

    filtraProdutoPorTabela(precos) {
        //console.log("----->Tamanho do precos(antes): " + JSON.stringify(precos));
        let filtrado = precos.filter(this.filtro);
        this.setState({ precos: filtrado });
        //console.log("----->Tamanho do precos: " + JSON.stringify(filtrado));
        this.requestProduto();
    }

    componentDidMount() {
        this.setState({ cliente: this.props.dataFromParent.escolhido })
        this.setState({ data: this.props.dataFromParent.dataFat })
        console.log("Codigo: "+this.props.dataFromParent.escolhido.A1_NREDUZ+"   "+this.props.dataFromParent.dataFat)
        this.requestSaldo();
    }

    requestProduto = async e => {
        try {
            const response = await api.get("/produtos/get",
                {
                    headers: {
                        'this.state-Type': 'application/json',
                        'token': getToken()
                    }
                });
            this.filtraProdutoDaTabela(response.data.data);
        } catch (err) {
            console.log("ERRO" + err.stack);
            const pkg = {itens:'', nomes: '', stage:'Erro'}
            this.props.parentCallback(pkg);
        }
    }

    requestPrecos = async e => {
        try {
            const response = await api.get("/precos/get",
                {
                    headers: {
                        'this.state-Type': 'application/json',
                        'token': getToken()
                    }
                });
            //console.log("resultado do resquest preço" + response.data.data )
            this.filtraProdutoPorTabela(response.data.data);
        } catch (err) {
            console.log("ERRO" + err.stack);
            const pkg = {itens:'', nomes: '', stage:'Erro'}
            this.props.parentCallback(pkg);
        }
    }

    requestSaldo = async e => {
        try {
            const response = await api.get("/saldos/get",
                {
                    headers: {
                        'this.state-Type': 'application/json',
                        'token': getToken()
                    }
                });
            this.setState({ saldos: response.data.data });
            //console.log("----->Tamanho do saldos: " + response.data.data);
        } catch (err) {
            //console.log("ERRO" + err.stack);
            const pkg = {itens:'', nomes: '', stage:'Erro'}
            this.props.parentCallback(pkg);
        }
        this.requestPrecos();
    }

    renderDropdownOptions() {
        return (this.state.produtos.map(produto => {
            return (
                <option value={produto.B1_COD} key={produto.B1_COD}>{`${produto.B1_XREDUZ} (${produto.B1_UM})`}</option>
            )
        })
        )
    }

    verificaExistencia(codProduto) {
        try {
            for (var obj of this.state.itensDoPedido) {
                if (obj.B1_COD === codProduto)
                    return true;
            }
            return false;
        } catch (erro) {
            return true;
        }
    }
     
    handleRemove(codProduto) {
        let Novo = this.state.itensDoPedido;
        let index = 0;
        for (let obj of Novo) {
            if (obj.B1_COD === codProduto) {
                console.log(`achou index ${index} com codP = a ${codProduto} e b1cod ${obj.B1_COD}`)
                Novo.splice(index, 1);
                this.setState({ itensDoPedido: Novo })
                return;
            }
            index++;
        }
    }

    handleAdd = e => {
        //console.log("SELECIONADO ----> " + this.state.selecionado)
        if (this.state.selecionado === '' || this.state.selecionado === 'nulo' || this.verificaExistencia(this.state.selecionado))
            return;
        console.log("PASSOU O RETORNO")
        let lista = this.state.itensDoPedido;
        lista.push(this.getProduto(this.state.selecionado));
        this.setState({ itensDoPedido: lista });
        this.setState({ pMap: this.state.pMap.set(this.state.selecionado, this.getPreco(this.state.selecionado)) });
        this.setState({ nMap: this.state.nMap.set(this.state.selecionado, this.getNome(this.state.selecionado)) });
        this.setState({ qMap: this.state.qMap.set(this.state.selecionado, 1) });
        this.setState({ vpMap: this.state.vpMap.set(this.state.selecionado, false),vqMap: this.state.vqMap.set(this.state.selecionado, false)})
    }

    getNome(codProduto){
        for (var obj of this.state.produtos) {
            if (obj.B1_COD === codProduto)
                return obj.B1_XREDUZ;
        }
    }
    
    getProduto(codProduto) {
        for (var obj of this.state.produtos) {
            if (obj.B1_COD === codProduto)
                return obj
        }
    }

    renderTable() {
        // console.log("render table ---")
        return (
            <table id="produtos-table" className = 'table-container'>
                <thead>
                    <tr>
                        <th>Produto</th>
                        <th>Quantidade</th>
                        <th>UN</th>
                        <th>Estoque</th>
                        <th>Preço</th>
                        <th>Preço (Total)</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {this.renderRow()}
                </tbody>
            </table>
        )
    }

    getSaldo(codProduto) {
        for (var obj of this.state.saldos) {
            if (obj.B6_PRODUTO === codProduto) {
                return obj.B6_SALDO
            }
        }
    }

    getPreco(codProduto) {
        for (var obj of this.state.precos) {
            if (obj.DA1_CODPRO === codProduto)
                return obj.DA1_PRCVEN
        }
    }

    getMax(codProduto) {
        for (var obj of this.state.precos) {
            if (obj.DA1_CODPRO === codProduto)
                return obj.DA1_XDESMA;
        }
    }

    getMin(codProduto) {
        for (var obj of this.state.precos) {
            if (obj.DA1_CODPRO === codProduto)
                return obj.DA1_XDESMI;
        }
    }

    verificaQTD(atual, max) {
        return (atual <= max ? true : false)
    }

    renderRow() {
        //console.log("render row itens"+ this.state.itensDoPedido)
        return this.state.itensDoPedido.map(itens => {
            return (
                <tr key={itens.B1_COD}>
                    <td>{itens.B1_XREDUZ}</td>
                    <td><input type="number" max={this.getSaldo(itens.B1_COD)} min='1' placeholder={this.state.qMap.get(itens.B1_COD)}
                    style={this.state.vqMap.get(itens.B1_COD)?{border:'2px solid #FF0000'}:{}}
                        onChange={e => ((e.target.value > this.getSaldo(itens.B1_COD)||e.target.value<=0) ? this.setState({ qMap: this.state.qMap.set(itens.B1_COD, 1), vqMap: this.state.vqMap.set(itens.B1_COD, true) }) : this.setState({ qMap: this.state.qMap.set(itens.B1_COD, e.target.value), vqMap: this.state.vqMap.set(itens.B1_COD, false) }))}
                        /></td>
                    <td>{itens.B1_UM}</td>
                    <td>{this.getSaldo(itens.B1_COD)}</td>
                    <td><input type="number" placeholder={this.state.pMap.get(itens.B1_COD)}
                    style={this.state.vpMap.get(itens.B1_COD)?{border:'2px solid #FF0000'}:{}}
                        onChange={e => this.verificaPreco(e.target.value, itens.B1_COD) ? this.setState({ pMap: this.state.pMap.set(itens.B1_COD, e.target.value),vpMap: this.state.vpMap.set(itens.B1_COD, false) }) : this.setState({ pMap: this.state.pMap.set(itens.B1_COD, this.getPreco(itens.B1_COD)), vpMap: this.state.vpMap.set(itens.B1_COD, true)})} /></td>
                    <td>{this.state.pMap.get(itens.B1_COD) * this.state.qMap.get(itens.B1_COD)}</td>
                    <td><button onClick={e => this.handleRemove(itens.B1_COD)}><i className="fa fa-trash"></i></button></td>
                </tr>
            )
        })
    }

    verificaPreco(valor, codigo) {
        var pMax = ((this.getMax(codigo) + 100) / 100) * this.getPreco(codigo);
        var pMin = ((100 - this.getMin(codigo)) / 100) * this.getPreco(codigo);
       // return (valor < pMax && valor > pMin)
       return true
    }

    renderDropdown() {
        return (
        <div >
            <select id = 'itens-drop' onChange={e => { e.target.value === 'nulo' ? this.setState({ selecionado: 'nulo' }) : this.setState({ selecionado: e.target.value }) }}>
                <option value='nulo' >Selecione um produto</option>
                {this.renderDropdownOptions()}
            </select>
            <button id = 'add-button' onClick={this.handleAdd}>Adicionar ao Pedido</button>
        </div>)
    }

    handleFinaliza() {
        let itens = [];
        if (this.state.itensDoPedido.length === 0) {

            this.setState({erro: true})

        } else {
            for (const item of this.state.itensDoPedido) {
                const aux = {
                    produto: item.B1_COD,
                    quantidade: this.state.qMap.get(item.B1_COD),
                    preco: this.state.pMap.get(item.B1_COD)
                }
                itens.push(aux)
            }
            this.setState({ arrayData: itens });
            console.log(JSON.stringify(itens))
            const pkg = {itens: itens, nomes: this.state.nMap, stage:'5'}
            this.props.parentCallback(pkg);
        }
    }

    render() {
        return (
            <div>
                {this.renderDropdown()}
                <hr/>
                <div id='table-div'>
                {this.renderTable()}
                </div>  
                <button id = 'final-butt' onClick={e => (this.handleFinaliza())}>Finalizar</button>
                {this.state.erro?<span id = 'final-span'>O pedido não pode ser finalizado sem itens!</span>:<span></span>}
            </div>
        )
    } 
}


