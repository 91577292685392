import React, { Component } from 'react'
import Main from '../templates/Main'
import Escolha from './escolhaCliente/Escolha'
import Detalhes from './detalheCliente/Detalhes'
import IncluiPedido from './incluiPedido/IncluiPedidoModus1'
import IncluiPedidoMd2 from './incluiPedido/IncluiPedidoModus2'
import DataeMsg from './setDataeMsg/DataeMsg'
import Finaliza from './finaliza/Finaliza'
import Sucesso from './resposta/Sucesso'
import Erro from './resposta/Erro'
import Risco from './resposta/RiscoE'
import Bloq from './resposta/ClienteBloqueado'
import { getCat } from '../../services/login'
import './Pedidos.css'


const headerProps = {
    icon: 'dollar',
    title: " Novo Pedido"
}

export default class Pedido extends Component {

    state = {
        escolhido: '',
        estagio: '1',
        msgNota:'',
        msgEqp:'',
        dataFat:'',
        modus:'',
        nMap: new Map(),
        data:[]
    }

    callbackEscolha = (childData) => {
        this.setState({ escolhido: childData.data})
        this.setState({ estagio: childData.stage })
    }

    callbackResposta = (childData) => {
        this.setState({estagio: childData})
    }

    callbackMsg = (childData ) =>{
        console.log('mensagem1: '+childData.msgNota+' Mensagem 2: '+childData.msgEqp+"estagio: "+childData.toStage+"data"+childData.data)
        this.setState({msgNota: childData.msgNota, msgEqp: childData.msgEqp, estagio:childData.toStage, dataFat: childData.data})
        this.setModus(childData.data);
    }

    callbackFinaliza=(childData)=>{
        this.setState({data:childData.itens});
        this.setState({nMap:childData.nomes});
        this.setState({estagio: childData.stage});

    }

    setModus(fat){
        //2019-12-26
        console.log('------>'+getCat())
        if(getCat()==='02'){
         var data = new Date()
        var hoje = (`${data.getFullYear()}-${(data.getMonth() +1)<10?"0"+(data.getMonth() +1):(data.getMonth() +1)}-${data.getDate()<10?"0"+data.getDate():data.getDate()}`)
        console.log("Hoje "+hoje+"  Fat"+fat)
        this.setState({modus:(hoje===fat?'1':'2')})}
        else{
            this.setState({modus:'2'})
        }
            
    }

    conditionalRender() {
        if (this.state.estagio === '1') {
            return (
                <Escolha parentCallback={this.callbackEscolha} >
                </Escolha>
            )
        }
        else if(this.state.estagio ==='2') {
            return (
                <div>
                    <Detalhes dataFromParent ={this.state.escolhido}></Detalhes>
                    <div>
                    <button id = 'voltar-button' onClick={e => this.setState({ estagio: '1' })}>Voltar</button>
                    <button id = 'avanc-button' onClick={e => this.setState({ estagio: '3' })}>Avançar</button>
                    </div>
                </div>
            )
        }else if(this.state.estagio==='3'){
            return(
                <div>
                     <DataeMsg parentCallback={this.callbackMsg}></DataeMsg>
                </div>
            )
        }
        else if(this.state.estagio ==='4')
            return(
                <div>
                    {this.state.modus==='1'?<IncluiPedido dataFromParent = {this.state} parentCallback={this.callbackFinaliza}></IncluiPedido>:<IncluiPedidoMd2 dataFromParent = {this.state} parentCallback={this.callbackFinaliza}></IncluiPedidoMd2>}
                </div>
            )
        else if( this.state.estagio === '5'){
           return ( <div>
                <Finaliza dataFromParent = {this.state} parentCallback={this.callbackResposta}></Finaliza>
            </div>)
        }
        else if( this.state.estagio ==='6'){
            return(<div>
                <Sucesso></Sucesso>
            </div>)
        }else if(this.state.estagio === '7'){
            return(
                <div>
                    <Risco></Risco>
                </div>
            )
        }
        else if(this.state.estagio === '8'){
            return(
                <div>
                    <Bloq></Bloq>
                </div>
            )
        }
        else{
            return(
                <div>
                    <Erro></Erro>
                </div>
            )
        }
    }

    render() {
        return (
            <Main {...headerProps}>
                {this.conditionalRender()}
            </Main>
        );
    }

}
