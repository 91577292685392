import React, { Component } from 'react'
import Main from '../templates/Main'
import api from '../../services/api'
import { getCod, getSuper, getGerente, getNome } from '../../services/login'
import { getToken } from '../../services/login'
import Axios from 'axios'
import Sucesso from './resposta/Sucesso'
import Erro from './resposta/Erro'
import Duplicidade from './resposta/Duplicidade'
import './CadastroCliente.css'

const headerProps = {
    icon: 'user',
    title: " Cadastro de Clientes"
}

var eMap = new Map();
var cMap = new Map();
var oMap = new Map();

export default class Cadastro extends Component {
    constructor(props) {
        super(props);
        eMap.set('RO', '11');
        eMap.set('AC', '12');
        eMap.set('AM', '13');
        eMap.set('RR', '14');
        eMap.set('PA', '15');
        eMap.set('AP', '16');
        eMap.set('TO', '17');
        eMap.set('MA', '21');
        eMap.set('PI', '22');
        eMap.set('CE', '23');
        eMap.set('RN', '24');
        eMap.set('PB', '25');
        eMap.set('PE', '26');
        eMap.set('AL', '27');
        eMap.set('SE', '28');
        eMap.set('BA', '29');
        eMap.set('MG', '31');
        eMap.set('ES', '32');
        eMap.set('RJ', '33');
        eMap.set('SP', '35');
        eMap.set('PR', '41');
        eMap.set('SC', '42');
        eMap.set('RS', '43');
        eMap.set('MS', '50');
        eMap.set('MT', '51');
        eMap.set('GO', '52');
        eMap.set('DF', '53');
        this.requestClientes();
    }
    //------------------------------------------------------------------------------------------------------------------
    requestClientes = async e => {
        try {
            const response = await api.get("/clientes/get",
                {
                    headers: {
                        'this.state-Type': 'application/json',
                        'token': getToken()
                    }
                });
            this.setState({ clientes: response.data.data });
            console.log(response)
        } catch (err) {
            console.log(err)
        //const pkg = {data:'', stage: 'Erro'}
        //this.props.parentCallback(pkg);
        }
    }

    handleEstado = e => {
        this.setState({ estado: e.target.value })
        //console.log("CODIGO: " + eMap.get(e.target.value));
        this.findCode(eMap.get(e.target.value));
    }


    revisedContent() {

        const resp = {
            cnpj: this.state.cnpj,
            inscricao: this.state.inscricao,
            razao: this.state.razao.normalize('NFD').replace(/[\u0300-\u036f]/g, "").replace(/[^a-zA-Z0-9 ]/g, "").toUpperCase(),
            endereco: this.state.endereco.normalize('NFD').replace(/[\u0300-\u036f]/g, "").replace(/[^a-zA-Z0-9 ]/g, "").toUpperCase(),
            bairro: this.state.bairro.normalize('NFD').replace(/[\u0300-\u036f]/g, "").replace(/[^a-zA-Z0-9 ]/g, "").toUpperCase(),
            codigo_cidade: this.state.codigo_cidade,
            estado: this.state.estado,
            cidade: this.state.cidade.normalize('NFD').replace(/[\u0300-\u036f]/g, "").replace(/[^a-zA-Z ]/g, "").toUpperCase(),
            cep: this.state.cep,
            vendedor: this.state.vendedor,
            supervisor: this.state.supervisor,
            gerente: this.state.gerente,
            credito: this.state.credito,
            cd_ou_loja: this.state.cd_ou_loja,
            pagamento: this.state.pagamento,
            contato: this.state.contato.normalize('NFD').replace(/[\u0300-\u036f]/g, "").replace(/[^a-zA-Z ]/g, "").toUpperCase(),
            ddd: this.state.ddd,
            telefone: this.state.telefone,
            email_nfe: this.state.email_nfe.toUpperCase(),
            abrangencia: this.state.abrangencia,
            porte: this.state.porte,
            segmento: this.state.segmento,
            tipo: this.state.tipo,
            contato_cobranca: this.state.contato_cobranca.normalize('NFD').replace(/[\u0300-\u036f]/g, "").replace(/[^a-zA-Z ]/g, "").toUpperCase(),
            email_cobranca: this.state.email_cobranca.toUpperCase(),
            ddd_cobranca: this.state.ddd_cobranca,
            telefone_cobranca: this.state.telefone_cobranca,
            informacoes: this.state.informacoes.normalize('NFD').replace(/[\u0300-\u036f]/g, "").replace(/[^a-zA-Z0-9 ]/g, "").toUpperCase(),
            inclusao: this.state.inclusao,
            
        }

        return resp;

    }


    handleSubmit = async e => {

        console.log("JSON:  " + JSON.stringify(this.revisedContent()));

        if(this.verificaCNPJ()){

        e.preventDefault();
        console.log("Teste" + getToken() + "|");
            const response = await api.post("/clientes/post", this.revisedContent(), {
                headers: {
                    'this.state-Type': 'application/json',
                    'token': getToken()
                }
            }).then(response=>{

                console.log(response.data);
            console.log(response.status);
            this.setState({resposta: '1'})

            }).catch(err =>{

                console.error("erro no handle: "+  err.response.message)
                this.setState({resposta: '2'})
            });
            
    }

    else {
    this.setState({resposta: '3'})
    }

    }

    findCode = async code => {
        cMap.clear();
        try {
            const response = await Axios.get(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${code}/municipios`);
            var i = 0;
            for (const tes of response.data) {
                i++;
                cMap.set(i, <option key={i} value={`${tes.id}`} >{tes.nome}</option>);
                oMap.set(`${tes.id}`, tes.nome);
            }
            this.setState({ arraycidades: cMap });
        } catch (erro) {
            console.log("DEU RUIM" + erro.stack);
            this.setState({resposta: '2'})
        }
    }

    

    setOnClick = e => {
        if(!this.state.flagBtnEnvia){
        this.setState({ vendedor: getCod(), gerente: getGerente(), supervisor: getSuper(), flagBtnEnvia: true });
        var dt = new Date();
        this.setState({
            inclusao: ("0" + new Date().getDate()).slice(-2) + "/" + ("0" + (new Date().getMonth()+1)).slice(-2) + "/" + dt.getFullYear()
                + " " + ("0" + new Date().getHours()).slice(-2) + ":" + ("0" + (new Date().getMinutes())).slice(-2) + ":" + ("0" + new Date().getSeconds()).slice(-2) + " POR " + getNome()
        });
    }
    }

    tentativa = e => {
        console.log("valor do mapa " + oMap.get(e.target.value) + " valor " + e.target.value);
        this.setState({ codigo_cidade: `${e.target.value}`.substring(2, 7), cidade: oMap.get(e.target.value) });
    }

    verificaCNPJ(){
        for(let i = 0; i < this.state.clientes.length; i++){
            //console.log(`comparando ${aux.A1_CGC} com ${this.state.cnpj} \n` )
            if(this.state.clientes[i].A1_CGC === this.state.cnpj){    
                return false;
            }
        }
        return true;
    }


    state = {
        cnpj: '',
        inscricao: '',
        razao: '',
        endereco: '',
        bairro: '',
        codigo_cidade: '',
        estado: '',
        cidade: '',
        cep: '',
        vendedor: '',
        supervisor: '',
        gerente: '',
        credito: '',
        cd_ou_loja: '',
        pagamento: '',
        contato: '',
        ddd: '',
        telefone: '',
        email_nfe: '',
        abrangencia: '',
        porte: '',
        segmento: '',
        tipo: '',
        contato_cobranca: '',
        email_cobranca: '',
        ddd_cobranca: '',
        telefone_cobranca: '',
        informacoes: '',
        inclusao: '',
        clientes:[],
        arraycidades: [],
        resposta:'0',
        flagBtnEnvia: false,
    }


    renderForm(){
        return(
            <form className="cadastro" onSubmit={this.handleSubmit} >
                    <h6>CNPJ:</h6>
                    <input
                        placeholder='CNPJ(Somente Números)'
                        type='text'
                        maxLength="14"
                        required
                        onChange={e => this.setState({ cnpj: e.target.value })}
                    />
                    <h6>Nome do Comprador:</h6>
                    <input
                        placeholder='Nome do Comprador:'
                        type='text'
                        maxLength="15"
                        required
                        onChange={e => this.setState({ contato: e.target.value })}
                    ></input>
                    <h6>DDD do Telefone do Comprador:</h6>
                    <input placeholder='DDD do Telefone do Comprador' type='text' maxLength="2" required onChange={e => this.setState({ ddd: e.target.value })} ></input>
                    <h6>Telefone do Comprador:</h6>
                    <input placeholder='Telefone do Comprador' type='text' maxLength="9" required onChange={e => this.setState({ telefone: e.target.value })} ></input>
                    <h6>Nome do Responsável pela Cobrança:</h6>
                    <input placeholder='Nome do Responsável pela Cobrança' type='text' required maxLength="15" onChange={e => this.setState({ contato_cobranca: e.target.value })}></input>
                    <h6>DDD Telefone de Cobrança:</h6>
                    <input placeholder='DDD Telefone de Cobrança:' type='text' maxLength="2" required onChange={e => this.setState({ ddd_cobranca: e.target.value })}></input>
                    <h6>Telefone de Cobrança:</h6>
                    <input placeholder='Telefone de Cobrança' type='text' maxLength="9" required onChange={e => this.setState({ telefone_cobranca: e.target.value })} ></input>
                    <h6>Email de Cobrança:</h6>
                    <input placeholder='Email de Cobrança:' type='email' maxLength="60" required onChange={e => this.setState({ email_cobranca: e.target.value })} ></input>
                    <h6>Email envio de XML:</h6>
                    <input placeholder='Email envio de XML' type='email' maxLength="60" required onChange={e => this.setState({ email_nfe: e.target.value })} ></input>
                    <div>
                        <h6>Segmento:</h6>
                        <select onChange={e => this.setState({ segmento: e.target.value })} >
                            <option defaultValue value="00">Selecione</option>
                            <option value="01">Atacado/Distribuição</option>
                            <option value="02">Food Service</option>
                            <option value="03">Varejo</option>
                            <option value="04">Outros</option>
                        </select>
                    </div>
                    <div>
                        {this.selectCreator(this.state.segmento)}
                    </div>
                    <div>
                        <h6>CD ou Loja?</h6>
                        <select onChange={e => this.setState({ cd_ou_loja: e.target.value })} >
                            <option defaultValue value="00">Selecione</option>
                            <option value="L">Loja</option>
                            <option value="C">CD</option>
                        </select>
                    </div>
                    <div>
                        <h6>Condição de Pagamento:</h6>
                        <select onChange={e => this.setState({ pagamento: e.target.value })} >
                            <option defaultValue value="00">Selecione</option>
                            <option value="105">21 Dias</option>
                            <option value="106">28 Dias</option>
                            <option value="135">35 Dias</option>
                            <option value="144">45 Dias</option>
                            <option value="138">30 Dias</option>
                            <option value="909">14 Dias</option>
                            <option value="102">7 Dias</option>
                        </select>
                    </div>
                    <div>
                        <h6>Abrangência:</h6>
                        <select onChange={e => this.setState({ abrangencia: e.target.value })} >
                            <option defaultValue value="00">Selecione</option>
                            <option value="01">Local</option>
                            <option value="02">Nacional</option>
                            <option value="03">Regional</option>
                        </select>
                    </div>
                    <h6>Insc. Estadual:</h6>
                    <input id="inscEst" value={this.state.inscricao} placeholder='Insc. Estadual (Somente Números)' type='text' required maxLength="18" onChange={e => this.setState({ inscricao: e.target.value })} ></input>
                    <h6>Razão Social:</h6>
                    <input placeholder='Razão Social' type='text' required maxLength="80" onChange={e => this.setState({ razao: e.target.value })} ></input>
                    <div>
                        <h6>Estado:</h6>
                        <select onChange={this.handleEstado} >
                            <option defaultValue value="NULL">Selecione um estado</option>
                            <option value="AC">Acre</option>
                            <option value="AL">Alagoas</option>
                            <option value="AP">Amapá</option>
                            <option value="AM">Amazonas</option>
                            <option value="BA">Bahia</option>
                            <option value="CE">Ceará</option>
                            <option value="DF">Distrito Federal</option>
                            <option value="ES">Espírito Santo</option>
                            <option value="GO">Goiás</option>
                            <option value="MA">Maranhão</option>
                            <option value="MT">Mato Grosso</option>
                            <option value="MS">Mato Grosso do Sul</option>
                            <option value="MG">Minas Gerais</option>
                            <option value="PA">Pará</option>
                            <option value="PB">Paraíba</option>
                            <option value="PR">Paraná</option>
                            <option value="PE">Pernambuco</option>
                            <option value="PI">Piauí</option>
                            <option value="RJ">Rio de Janeiro</option>
                            <option value="RN">Rio Grande do Norte</option>
                            <option value="RS">Rio Grande do Sul</option>
                            <option value="RO">Rondônia</option>
                            <option value="RR">Roraima</option>
                            <option value="SC">Santa Catarina</option>
                            <option value="SP">São Paulo</option>
                            <option value="SE">Sergipe</option>
                            <option value="TO">Tocantins</option>
                        </select>
                    </div>
                    <h6>Cidade:</h6>
                    <div>
                        <select onChange={this.tentativa}>
                            <option defaultValue value="NULL">Selecione uma cidade</option>
                            {Array.from(cMap)}
                        </select>
                    </div>
                    <h6>Endereço (com número):</h6>
                    <input placeholder='Endereço' type='text' required maxLength="40" onChange={e => this.setState({ endereco: e.target.value })} ></input>
                    <h6>Bairro:</h6>
                    <input placeholder='Bairro' type='text' required maxLength="30" onChange={e => this.setState({ bairro: e.target.value })} ></input>
                    <h6>CEP:</h6>
                    <input placeholder='CEP(Somente Números)' required type='text'
                    maxLength="8" onChange={e => this.setState({ cep: e.target.value })} ></input>
                    <h6>Limite de Crédito:</h6>
                    <input placeholder='Limite de Crédito' type="number" step="any" required onChange={e => this.setState({ credito: parseFloat(e.target.value) })} ></input>
                    <h6>Informações Adicionais:</h6>
                    <input id="infos" placeholder='Informações Adicionais' type='text' onChange={e => this.setState({ informacoes: e.target.value })} ></input>
                    <hr />
                    <button type="submit" onClick={this.setOnClick}>Salvar</button>
                </form>
        )
    }

    selectRender(){
        if(this.state.resposta==='0')
            return(this.renderForm())
        else if(this.state.resposta==='1')
            return (<Sucesso></Sucesso>);
        else if(this.state.resposta==='2')
            return (<Erro></Erro>);
        else
            return(<Duplicidade></Duplicidade>);
        }

    render() {
        return (
            <Main {...headerProps}>
                {this.selectRender()}
            </Main>
        )
    }


    selectCreator(selected) {
        if (selected === '01') {
            return (
                <div>
                    <div>
                        <h6>Tipo:</h6>
                        <select onChange={e => this.setState({ tipo: e.target.value })} >
                            <option defaultValue value="00">Selecione</option>
                            <option value="0001">Autoserviço/Atakarejo</option>
                            <option value="0002">Food Service</option>
                            <option value="0003">Frios</option>
                            <option value="0004">Secos e Molhados</option>
                        </select>
                    </div>
                    <div>
                        <h6>Porte:</h6>
                        <select onChange={e => this.setState({ porte: e.target.value })} >
                            <option defaultValue value="00">Selecione</option>
                            <option value="0001">Até 5 checkouts</option>
                            <option value="0002">De 6 a 20 checkouts</option>
                            <option value="0003">Acima de 20 checkouts</option>
                            <option value="0004">Até 9 vendedores</option>
                            <option value="0005">De 10 a 45 vendedores</option>
                            <option value="0006">De 50 a 100 vendedores</option>
                            <option value="0007">Acima de 100 vendedores</option>
                        </select>
                    </div>
                </div>
            )
        }
        else if (selected === '02') {
            return (
                <div>
                    <div>
                        <h6>Tipo:</h6>
                        <select onChange={e => this.setState({ tipo: e.target.value })} >
                            <option defaultValue value="00">Selecione</option>
                            <option value="005">Açougue</option>
                            <option value="006">Ambulante/Lancheiro</option>
                            <option value="007">Bar</option>
                            <option value="008">Cozinha industrial</option>
                            <option value="009">Hospital</option>
                            <option value="0010">Motel/Hotel</option>
                            <option value="0011">Industria</option>
                            <option value="0012">Lanchonete</option>
                            <option value="0013">Padaria</option>
                            <option value="0014">Pizzaria</option>
                            <option value="0015">Restaurante</option>
                        </select>
                    </div>
                    <div>
                        <h6>Porte:</h6>
                        <select onChange={e => this.setState({ porte: e.target.value })} >
                            <option defaultValue value="00">Selecione</option>
                            <option value="008">Pequeno porte</option>
                            <option value="009">Médio porte</option>
                            <option value="0010">Grande porte</option>
                        </select>
                    </div>
                </div>
            )
        }
        else if (selected === '03') {
            return (
                <div>
                    <div>
                        <h6>Tipo:</h6>
                        <select onChange={e => this.setState({ tipo: e.target.value })} >
                            <option defaultValue value="00">Selecione</option>
                            <option value="0016">Hipermercado</option>
                            <option value="0017">Mercado</option>
                            <option value="0018">Supermercado</option>
                        </select>
                    </div>
                    <div>
                        <h6>Porte:</h6>
                        <select onChange={e => this.setState({ porte: e.target.value })} >
                            <option defaultValue value="00">Selecione</option>
                            <option value="0011">Até 5 checkouts</option>
                            <option value="0012">De 6 a 20 checkouts</option>
                            <option value="0013">Acima de 20 checkouts</option>
                        </select>
                    </div>
                </div>
            )
        }
        else {
            return (
                <div>
                    <div>
                        <h6>Tipo:</h6>
                        <select onChange={e => this.setState({ tipo: e.target.value })} >
                            <option defaultValue value="00">Selecione</option>
                            <option value="0019">Outros</option>
                        </select>
                    </div>
                    <div>
                        <h6>Porte:</h6>
                        <select onChange={e => this.setState({ porte: e.target.value })} >
                            <option defaultValue value="00">Selecione</option>
                            <option value="0014">Outros</option>
                        </select>
                    </div>
                </div>
            )
        }
    }
}