import React, { Component } from 'react'
import Main from '../templates/Main'
import api from '../../services/api'
import { getCod, getSuper, getGerente, getNome } from '../../services/login'
import { getToken } from '../../services/login'
import { Block } from '@material-ui/icons'
import Erro from './Erro'
import Sucesso from './Sucesso'
import './Solicitacao.css'

const headerProps = {
    icon: 'credit-card',
    title: " Solicitação de Verba"
}

export default class Solicitacao extends Component {
    state = {
        IdVerba:'',
        DtInclusao:'',
        OrigemInclusao:'W',
        CNPJ:'',
        CodCliente:'',
        LojaCliente:'',
        TipoCliente:'',
        TblPreco:'',
        GrpVendas:'',
        ValorTotal:0,
        CodResp:'',
        Status:'',
        Obs:'',
        TipoPagamento:'',
        Banco:'',
        Agencia:'',
        AgenciaDV:'',
        ContaCorrente:'',
        ContaCorrenteDV:'',
        CNPJPagamento:'',
        data :[],
        
        //=======================================

        codFin:[],
        clientes: [],
        full_clientes: [],
        escolhido: '',
        render: 1,

        //=======================================

        Parcela: 0,
        CodFin:'',
        DtVenc:'',
        Valor:'',
        NegociadoDe:'', 
        NegociadoAte:'',
        erroAvanco:false,
        DtVencIni:'',
        Dateini:null,
        NParcela:1,
        //===
    }

    /*
        body do request
          const {
            IdVerba,
            TipoVerba,
            DtInclusao,
            OrigemInclusao,
            CNPJ,
            CodCliente,
            LojaCliente,
            TipoCliente,
            TblPreco,
            GrpVendas,
            ValorTotal,
            CodResp,
            Status,
            Obs,
            TipoPagamento,
            Banco,
            Agencia,
            AgenciaDV,
            ContaCorrente,
            ContaCorrenteDV,
            
            data:
            data[i].Parcela + "'
            data[i].CodFin + "'"
            data[i].DtVenc + "'"
            data[i].Valor + "'";
            data[i].NegociadoDe 
            data[i].NegociadoAte

        }
    */


    componentDidMount(){
      //request codfin /codfinanceiro/
      this.requestCodFin();
      this.requestClientes();
    }

    requestCodFin = async e => {
    try {
      //http://srqvendas.sitiorecantodoqueijo.com.br:10805/codfinanceiro/get
        const response = await api.get("/codfinanceiro/get",
            {
                headers: {
                    'this.state-Type': 'application/json',
                    'token': getToken()
                }
            });
        console.log(response.data.data)
       this.setState({codFin: response.data.data})
    } catch (err) {
        console.log(err)
        return 'ERRO';
    }
    }

    requestClientes = async e => {
    try {
        const response = await api.get("/clientes/get",
            {
                headers: {
                    'this.state-Type': 'application/json',
                    'token': getToken()
                }
            });
        this.setState({ clientes: response.data.data, full_clientes: response.data.data });
    } catch (err) {
        console.log(err)
    const pkg = {data:'', stage: 'Erro'}
    this.props.parentCallback(pkg);
    }
    }

    getHora(){
        var data = new Date();
        console.log((data.getHours()<10?"0"+data.getHours():data.getHours())
        +""+(data.getMinutes()<10?"0"+data.getMinutes():data.getMinutes())
        +""+(data.getSeconds()<10?"0"+data.getSeconds():data.getSeconds()));
        
        return (data.getHours()<10?"0"+data.getHours():data.getHours())
        +""+(data.getMinutes()<10?"0"+data.getMinutes():data.getMinutes())
        +""+(data.getSeconds()<10?"0"+data.getSeconds():data.getSeconds());
    }

    getData(){
        var data = new Date()
        var p1;
        p1 = (`${data.getFullYear()}${('0'+(data.getMonth()+1)).slice(-2)}${("0" + data.getDate()).slice(-2)}`);
        return p1
    }

    getDataMin(){
        var data;
        data = new Date();
        console.log(`${data.getFullYear()}-${(data.getMonth() +1)<10?"0"+(data.getMonth() +1):(data.getMonth() +1)}-${data.getDate()<10?"0"+data.getDate():data.getDate()}`)
        return (`${data.getFullYear()}-${(data.getMonth() +1)<10?"0"+(data.getMonth() +1):(data.getMonth() +1)}-${data.getDate()<10?"0"+data.getDate():data.getDate()}`);
    }

    getDataVenc(){
        var data = new Date(Date.now()+86400000*this.state.escolhido.E4_COND_1);
        console.log(`${data.getFullYear()}-${(data.getMonth() +1)<10?"0"+(data.getMonth() +1):(data.getMonth() +1)}-${data.getDate()<10?"0"+data.getDate():data.getDate()}`)
        return(`${data.getFullYear()}-${(data.getMonth() +1)<10?"0"+(data.getMonth() +1):(data.getMonth() +1)}-${data.getDate()<10?"0"+data.getDate():data.getDate()}`);
    }

    getDataInicial(parcela){
        var dtCorrigida = this.reviseContent(this.state.DtVencIni)
        var ini = new Date(parseInt(dtCorrigida.substring(0,4)),parseInt(dtCorrigida.substring(4,6))-1,parseInt(dtCorrigida.substring(6)));
        console.log('->p '+(86400000*(parcela-1)*30));
        console.log(ini);
        var data = new Date(ini.getTime()+(86400000*(parcela-1)*30));
        console.log(`${data.getFullYear()}-${(data.getMonth() +1)<10?"0"+(data.getMonth() +1):(data.getMonth() +1)}-${data.getDate()<10?"0"+data.getDate():data.getDate()}`)
        return(`${data.getFullYear()}-${(data.getMonth() +1)<10?"0"+(data.getMonth() +1):(data.getMonth() +1)}-${data.getDate()<10?"0"+data.getDate():data.getDate()}`);
    }

    makeId(){
        return getCod()+this.getData()+this.getHora();
    }

    reviseBody(){

        return  {
                IdVerba: this.makeId(),
                DtInclusao: this.getData(),
                OrigemInclusao:'W',
                TipoVerba:'C',
                CNPJ:this.state.escolhido.A1_CGC,
                CodCliente:this.state.escolhido.A1_COD,
                LojaCliente:this.state.escolhido.A1_LOJA,
                TipoCliente:this.state.escolhido.A1_XGC,
                TblPreco:this.state.escolhido.A1_TABELA,
                GrpVendas:this.state.escolhido.A1_GRPVEN.substring(0,6),
                ValorTotal:this.state.ValorTotal,
                CodResp:getCod(),
                Status:'EM ANALISE',
                CNPJPagamento: (this.state.CNPJPagamento===''?this.state.escolhido.A1_CGC:this.state.CNPJPagamento),
                Obs:this.state.Obs,
                TipoPagamento:this.state.TipoPagamento,
                Banco:this.state.Banco,
                Agencia:this.state.Agencia,
                AgenciaDV:this.state.AgenciaDV,
                ContaCorrente:this.state.ContaCorrente,
                ContaCorrenteDV:this.state.ContaCorrenteDV,
                data :this.state.data,       
        }
    }

    handleSubmit = async e => {
        
        console.log("JSON:  " + JSON.stringify(this.reviseBody()));
        //console.log("Teste" + getToken() + "|");
        try {
            const response = await api.post("/verbas/post", this.reviseBody(), {
                headers: {
                    'this.state-Type': 'application/json',
                    'token': getToken()
                }
            });
            //console.log(response.data);
            //console.log(response.status);
            //this.props.parentCallback('6');
            if(response.status==='200'){
                this.setState({render:'99'})
            }else{
                this.setState({render:'100'})
            }
        }
        catch (err) {
            console.error("erro no handle: " + err.stack)
           // this.props.parentCallback('Erro');
        }
    }

    conditionalRender(){
        if(this.state.render === 2)
        {
            return (
                <div className = 'dataForm'>
                  {this.renderStageTwo()}
                </div>
            )
        }
        else if(this.state.render===1)
        return (
            <div className = 'dataForm'>
              {this.renderStageOne()}
            </div>
        )
        else if(this.state.render===3)
        return (
            <div className = 'dataForm'>
              {this.renderStageThree()}
            </div>
        )
        else if (this.state.render ==='99'){
            return(<Erro></Erro>)
        }else if (this.state.render ==='100'){
            return(<Sucesso></Sucesso>)
        }
    }
    
    render() {
        return(
            <Main {...headerProps}>
            {this.conditionalRender()}
            </Main>
        )
    }
    //=========================================== RENDER FORM PARTE 1=======================================

    podeAvancar(){
        
        if(this.state.NegociadoDe!=='' && this.state.NegociadoAte!==''){ 
        if(this.state.TipoPagamento === '0'){
            console.log("passou o tipo de verba")
            if(this.state.Banco !== ''){
                console.log("passou o banco ")
                if(this.state.Agencia !== ''){
                    console.log("passou a agencia")
                    if(this.state.AgenciaDV !== ''){
                        console.log("passou o agencia dv")  
                        if(this.state.ContaCorrente !== ''){
                            console.log("passou o cc")
                            if(this.state.ContaCorrenteDV !== ''){
                                console.log("passou o ccdv")
                                return true;
                            }else{
                                return false;
                            }
                        }else{
                            return false;
                        }
                    }else{
                        return false;
                    }
                }else{
                    return false;
                }
            }else{
                return false
            }
        }else{
            if(this.state.TipoPagamento !== '' && this.state.TipoPagamento !== 'NULL')
                return true;
            return false;
        }}
    }
    
    renderStageTwo() {
        return (
            <div id ="msg-main-div" className = 'dataForm'>

                    <h6>Motivação da Solicitacão de Verba</h6>
                    <textarea type = 'multline'name="msgEqp" onChange={e => this.setState({ Obs: e.target.value })}></textarea>
                    <div className = 'vbCol'>
                    <div className = 'impTtl'>
                    <h6>Negociado de</h6>
                    <input placeholder='Negociado de' type='date' maxLength="60"
                     onChange={e => this.setState({ NegociadoDe: e.target.value })}
                    ></input>
                    </div>
                    <div className = 'impTtl'>
                    <h6>Negociado até</h6>
                    <input placeholder='Negociado até' type='date' maxLength="60"
                    disabled = {this.state.NegociadoDe===''}
                    min = {this.state.NegociadoDe} 
                    onChange={e => this.setState({ NegociadoAte: e.target.value })} ></input>
                    </div>
                    </div>
                    <h6>Tipo de pagamento:</h6>
                    <select onChange={e => this.setState({ TipoPagamento: e.target.value})} >
                            <option defaultValue value="NULL">Selecione</option>
                            <option value="0">Deposito</option>
                            <option value="1">Abatimento</option>
                    </select>
                    <hr />
                    <div class = 'devForm' hidden = {!(this.state.TipoPagamento === '0')}>
                    <h6>Código do Banco</h6>
                    <input placeholder='Banco' disabled={!(this.state.TipoPagamento === '0')} type='text' maxLength="3"  onChange={e => this.setState({ Banco: e.target.value })} ></input>
                    <h6>Agencia</h6>
                    <input placeholder='Agencia' type='text' maxLength = '4' disabled={!(this.state.TipoPagamento === '0')}   onChange={e => this.setState({ Agencia: e.target.value })} ></input>
                    <h6>DV da Agencia</h6>
                    <p>(O dígito da agencia é o número que aparece logo após o final do número da sua conta, depois de um traço ou espaço)</p>
                    <input placeholder='DV da Agencia' type='text' maxLength="1" disabled={!(this.state.TipoPagamento === '0')} onChange={e => this.setState({ AgenciaDV: e.target.value })} ></input>
                    <h6>Conta Corrente</h6>
                    <input placeholder='Conta Corrente' type='text' maxLength="20" disabled={!(this.state.TipoPagamento === '0')} onChange={e => this.setState({ ContaCorrente: e.target.value })} ></input>
                    <h6>DV da Conta corrente</h6>
                    <p>(O dígito da conta é o número que aparece logo após o final do número da sua conta, depois de um traço ou espaço)</p>
                    <input placeholder='DV da Conta corrente' type='text' maxLength="1" disabled={!(this.state.TipoPagamento === '0')} onChange={e => this.setState({ ContaCorrenteDV: e.target.value })} ></input>
                    <h6>CNPJ de Pagamento</h6>
                    <input placeholder='CNPJ de Pagamento' type='text' defaultValue = {this.state.escolhido.A1_CGC} maxLength="14" disabled={!(this.state.TipoPagamento === '0')} onChange={e => this.setState({ CNPJPagamento: e.target.value })} ></input>
                    </div>
                    <br></br>
                    <p hidden={!this.state.erroAvanco}>É necessário preencher todos os campos para continuar!</p>
                    <div id = 'div-buttons'>
                        <div>
                        <button id = 'avanc-button' type = 'submit' onClick={e => (this.podeAvancar()?this.setState({render:3}):this.setState({erroAvanco:true}))}>Próximo</button>
                        </div>
                    </div>
            </div>
        )
    }

    //=========================================== RENDER FORM PARTE 2 =======================================
    //data
  

    reviseContent(strg){
    return strg.normalize('NFD').replace(/[\u0300-\u036f]/g, "").replace(/[^a-zA-Z0-9 \n]/g, "").toUpperCase();
    }            

    addParcela(){
        this.handleRemove()
        console.log("---> entrou no add");
        if(this.state.CodFin !== '' && this.state.Valor !== '' && this.state.Parcela!==0){
            console.log("---> entrou no if");
            const valorParcela = (this.state.Valor/this.state.Parcela).toFixed(2);
            for(var i = 1; i<=this.state.Parcela; i++){
                console.log("---> entrou no for");
            const aux = {
                Parcela : i,
                CodFin: this.state.CodFin,
                DtVenc: this.reviseContent(this.getDataInicial(i)),
                Valor: valorParcela,
                NegociadoDe: this.reviseContent(this.state.NegociadoDe),
                NegociadoAte: this.reviseContent(this.state.NegociadoAte)
            }
            const axx = this.state.data;
            axx.push(aux);
            this.setState({data:axx,  ValorTotal: this.state.Valor, NParcela:i});
        }
    }
    
    }

    handleRemove() {
        let Novo = this.state.data;
        Novo.splice(0, this.state.data.length);
        this.setState({ data: Novo})
       // this.organizaOrdem();
        return;    
    }
    
    organizaOrdem(){

        let aux = [];
        let index = 1;
        for(let i of this.state.data){
            
            i.Parcela = index;
            i.DtVenc = this.reviseContent(this.getDataInicial(index));
            index++;
            aux.push(i);
        }
        this.setState({data: aux});
    }

    findClass(cod){
        for(let aux of this.state.codFin){
            if(cod === aux.cod)
                return aux.desc;
        }
    }
    
    renderStageThree(){
        return (
            <div id ="msg-main-div" className = 'dataForm'>
                    <div className = 'vbCol'>
                    <div className = 'impTtl'>
                    <h6>Classificação de Verba</h6>
                    <select onChange={e => this.setState({ CodFin: e.target.value})} >
                            <option key = 'Null' value = ''>SELECIONAR</option>
                            {this.state.codFin.map(aux => <option key = {aux.cod} value = {aux.cod} >{aux.desc}</option>)}                         
                    </select>
                    </div>
                    <div className = 'impTtl'>
                    <h6>Valor Total</h6>
                    <input placeholder='Valor' type='number' maxLength="60" onChange={e => this.setState({ Valor: e.target.value })} ></input>
                    </div>
                    </div>
                    <div className = 'vbCol'>
                    <div className = 'impTtl'>
                    <h6>Quantidade de Parcelas</h6>
                    <input placeholder='Quantidade de Parcelas' type='number' maxLength="60" onChange={e => this.setState({ Parcela: e.target.value })} ></input>
                    </div>
                    <div className = 'impTtl'>
                    <h6>Data de Vencimento</h6>
                    <input placeholder='Data inicial' type='date' min={this.getDataVenc()}  onChange={e => this.setState({ DtVencIni: e.target.value })} ></input>
                    </div>
                    </div>
                    
                    <div id = 'div-buttons'>
                        <div>
                        <button id = 'avanc-button' onClick={e => this.addParcela()}>Adcionar Parcela</button>
                        </div>
                    </div>
                    <h6>{`Valor total: ${this.state.Valor}`}</h6>
                    <div className = 'table-container'>
                        {this.renderTableParcela()}
                    </div>
                    <br></br>
                    <div id = 'div-buttons'>
                        <div>
                        <button id = 'avanc-button' type = 'submit' onClick={e => this.handleSubmit()}>Finalizar</button>
                        </div>
                    </div>
            </div>
        )
    }
    //===========================================================================================================


    renderRowParcela() {
         //data
                /*
                data[i].Parcela + "'
                data[i].CodFin + "'"
                data[i].DtVenc + "'"
                data[i].Valor + "'";
                data[i].NegociadoDe 
                data[i].NegociadoAte
                */
        //console.log("render row itens"+ this.state.itensDoPedido)
        return this.state.data.map(parcela => {
            return (
                <tr key={parcela.Parcela}>
                    <td>{this.findClass(parcela.CodFin)}</td>
                    <td>{`${parcela.DtVenc.substring(6)}/${parcela.DtVenc.substring(4,6)}/${parcela.DtVenc.substring(0,4)}`}</td>
                    <td>{parseFloat(parcela.Valor).toFixed(2)}</td>
                    <td>{parcela.Parcela}</td>
                </tr>
            )
        })
    }

    renderTableParcela() {
        // console.log("render table ---")
        return (
            <table id="clientes-table">
                <thead>
                    <tr>
                        <th>Classificação</th>
                        <th>Data de vencimento</th>
                        <th>Valor</th>
                        <th>N° Parcela</th>
                    </tr>
                </thead>
                <tbody>
                    {this.renderRowParcela()}
                </tbody>
            </table>
        )
    }

    //====================================== TABELA DE CLIENTES =================================================
    
    renderRow() {
        return this.state.clientes.map(cliente => {
            return (
                <tr key={`${cliente.A1_COD}-${cliente.A1_LOJA}`} onClick={e => { this.setState({render: 2, escolhido: cliente}) }}>
                    <td>{cliente.A1_NOME}</td>
                    <td>{cliente.A1_CGC}</td>
                    <td>{`${cliente.A1_COD}-${cliente.A1_LOJA}`}</td>
                    <td>{cliente.A1_MUN}</td>
                </tr>
            )
        })
    }

    renderTable() {
        return (
            <table id="clientes-table">
                <thead>
                    <tr className="header">
                        <th>Nome</th>
                        <th>CNPJ</th>
                        <th>Cod-Loja</th>
                        <th>Cidade</th>
                    </tr>
                </thead>
                <tbody>
                    {this.renderRow()}
                </tbody>
            </table>
        )
    }

    filtro = item => {
        let list = this.state.full_clientes;
        let result = [];
        for (const aux of list) {
            if (aux.A1_NREDUZ.includes(item.toUpperCase()) || aux.A1_CGC.includes(item.toUpperCase()) 
            || aux.A1_MUN.includes(item.toUpperCase()) || aux.A1_COD.includes(item.toUpperCase()) ) {
                result.push(aux);
            }
        }
        this.setState({ clientes: result })
    }

    renderStageOne() {
        return (
            <div>
                <div>
                <h3>Escolha um cliente:</h3>
                <hr/>
                <input type="text" id="filtro" onChange={e => this.filtro(e.target.value)} placeholder="Procurar cliente..."></input>
                </div>
                <div id="main-div">
                    {this.state.clientes===''?<h1 >Carregando...</h1>:this.renderTable()}
                </div>
            </div>
        )
    }


 //====================================== -=-=-=-=-=-=-=- =================================================

}


