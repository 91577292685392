import React, { Component } from 'react'
import './Detalhes.css'

export default class Detalhes extends Component {

    state = {
        cliente:''
    }
    componentDidMount(){
        this.setState({cliente: this.props.dataFromParent })
    }
   
    render(){
        return(
            <div className = 'detalhes'>
            <h2>Informações sobre o Cliente</h2>
            <hr/>
            <div className ='infos'>
            <div>
                <h5>Nome Fantasia: </h5>
                <p>{this.state.cliente.A1_NREDUZ}</p>
            </div>
            <hr/>
            <div>
                <h5>Razão Social: </h5>
                <p>{this.state.cliente.A1_NOME}</p>
            </div>
            <hr/>
            <div>
                <h5>CNPJ: </h5>
                <p>{this.state.cliente.A1_CGC}</p>
            </div>
            <hr/>
            <div>
                <h5>Rede: </h5>
                <p>{this.state.cliente.A1_GRPVEN}</p>
            </div>
            <hr/>
            <div>
                <h5>Cod-Loja: </h5>
                <p>{`${this.state.cliente.A1_COD} - ${this.state.cliente.A1_LOJA}`}</p>
            </div>
            <hr/>
            <div>
                <h5>Endereço: </h5>
                <p>{`${this.state.cliente.A1_END}, ${this.state.cliente.A1_BAIRRO} - ${this.state.cliente.A1_MUN} - ${this.state.cliente.A1_CEP}, ${this.state.cliente.A1_EST} `}</p>
            </div>
            <hr/>
            <div>
                <h5>Telefone: </h5>
                <p>{`${this.state.cliente.A1_DDD} ${this.state.cliente.A1_TEL}`}</p>
            </div>
            <hr/>
            <div>
                <h5>Email de NFe: </h5>
                <p>{this.state.cliente.A1_EMAIL}</p>
            </div>
            <hr/>
            <div>
                <h5>Email de Cobrança: </h5>
                <p>{this.state.cliente.A1_XEMAIL}</p>
            </div>
            <hr/>
            <div>
                <h5>Status: </h5>
                <p>{this.state.cliente.A1_XSTATI}</p>
            </div>
            <hr/>
            <div>
                <h5>Prazo de Pagamento: </h5>
                <p>{this.state.cliente.E4_DESCRI}</p>
            </div>
            <hr/>
            <div>
                <h5>Risco: </h5>
                <p>{this.state.cliente.A1_RISCO}</p>
            </div>
            <hr/>
            <div>
                <h5>Limite de Crédito: </h5>
                <p>{this.state.cliente.A1_LC}</p>
            </div>
            <hr/>
            <div>
                <h5>Desconto: </h5>
                <p>{this.state.cliente.A1_Q_DESC}</p>
            </div>
            </div>
            </div>
        )
    }
}