import React from 'react'
import './Resposta.css'
export default props =>
<div>
        <div className="display-4">Sucesso!</div>
        <hr />
        <div id= 'msg-div'>
        <p id = "resp-msg" className="mb-0">O pré-cadastro do cliente foi enviado com sucesso, assim que analisado, já estará disponível!</p>
        </div>
        <hr/>
        <a href='/app'><button id='ok-button'>Ok!</button></a>
</div>