import React from 'react'
import {Switch, Route, Redirect} from 'react-router'
import Home from '../components/home/Home'
import Cadastro from '../components/cliente/CadastroCliente'
import Pedido from '../components/pedido/Pedidos'
import Canhoto from '../components/canhotos/Canhotos'
import Devolucoes from '../components/devolucoes/Devolucoes'
import Alteracao from '../components/alteracao/ControlaFluxoAltera'
import NovaVerba from '../components/verbas/Solicitacao'
import MoniVerba from '../components/monitorverbas/Monitor'
import Saldos from '../components/saldos/Saldos'
import { isAuthenticated } from "../services/login";
//import HomeApp from './HomeApp'

const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route
      {...rest}
      render={props =>
        isAuthenticated() ? (
          <Component {...props} />
          ) : (
          < Redirect to={{ pathname: "/", state: { from: props.location } }} />
        )
      }
    />
  );

export default NavRoute =>
<Switch>
    <PrivateRoute path = "/app/clientes" component = {Cadastro}></PrivateRoute>
    <PrivateRoute path = "/app/pedidos" component = {Pedido}></PrivateRoute>
    <PrivateRoute path = "/app/canhotos" component = {Canhoto}></PrivateRoute>
    <PrivateRoute path = '/app/devolucoes' component = {Devolucoes}></PrivateRoute>
    <PrivateRoute path = '/app/alteracao' component = {Alteracao}></PrivateRoute>
    <PrivateRoute path = '/app/novaverba' component = {NovaVerba}></PrivateRoute>
    <PrivateRoute path = '/app/monitorverbas' component = {MoniVerba}></PrivateRoute>
    <PrivateRoute path = '/app/saldos' component = {Saldos}></PrivateRoute>
    <PrivateRoute exact path = "/app" component = {Home}></PrivateRoute>
    <Redirect from ='*' to ='/app' />
</Switch>