import React from 'react'
import {Switch, Route, Redirect, BrowserRouter} from 'react-router-dom'
import Home from './HomeApp'
import SignIn from '../login/Index'
import { isAuthenticated } from "../services/login";

const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route
      {...rest}
      render={props =>
        isAuthenticated() ? (
          <Component {...props} />
          ) : (
          < Redirect to={{ pathname: "/", state: { from: props.location } }} />
        )
      }
    />
  );

const Routes =() =>(
<BrowserRouter>
<Switch>
    <Route exact path = "/" component = {SignIn} />
    <PrivateRoute path = "/app" component = {Home}></PrivateRoute>
</Switch>
</BrowserRouter>
)
export default Routes;
//<Redirect from ='*' to ='/' />